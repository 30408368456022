
export default {
  data() {
    return {
      value: null,
    };
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  watch: {
    field: {
      immediate: true,
      handler: function () {
        this.value = this.field.value;
        this.uploadValue = [];
      },
    },
  },
  computed: {
    files() {
      const existingFiles = this.field.property.typedValue;
      if (!existingFiles) return [];

      return existingFiles.map((file, i) => {
        const basename = this.field.property.basenameProperty
          ? file.getField(this.field.property.basenameProperty).value
          : null;
        const name = basename || "File " + (i + 1);

        return {
          name,
          url: file.getField(this.field.property.fileProperty).property.fileUrl,
        };
      });
    },
  },
};
