import { ConditionalOperator } from "../plugins/Cruxtify/conditionals/GroupedConditional";
import {
  BooleanField,
  DateTimeField,
  EmailField,
  NumberField,
  RelationshipFieldMany,
  StringField,
} from "../plugins/Cruxtify/field-types";
import {
  BooleanProperty,
  DateTimeProperty,
  EmailProperty,
  NumberProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import { IppyDippyCrudModel } from "./IppyDippyCrudModel";

export class User extends IppyDippyCrudModel {
  public static icon = "mdi-account";
  protected static _typeLabel = "User";
  protected static asProperty = "user";
  public static api = {
    path: "users",
  };

  protected userIdField = ["id"];
  protected static routeBase = "users";

  public static modalEdit = true;

  protected labelGlue: string = " ";

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.SuperUser]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.Read,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "legacy_id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        displayRelative: true,
        label: "Created At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        displayRelative: true,
        label: "Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "is_admin",
        label: "Super Admin Account",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Hidden
        ),
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "is_group_admin",
        label: "Group Admin Account",
        computed: true,
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Hidden
        ),
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "is_super_user",
        userPermissions: UserPermission.Hidden,
        computed: true,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "first_name",
        label: "First Name",
        sortable: true,
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "last_name",
        label: "Last Name",
        sortable: true,
      },
    },
    {
      type: EmailProperty,
      opts: {
        name: "email",
        label: "Email",
        sortable: true,
        rules: ["required", "email"],
      },
    },
    {
      type: EmailProperty,
      opts: {
        name: "personal_email",
        label: "Personal Email",
        sortable: true,
        description:
          "A personal address can be used to recover access to an account if the school-based email is no longer accessible in the future.",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "phone",
        label: "Phone",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "password",
        label: "Password (leave blank to ignore)",
        sortable: false,
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
            [UserRole.SuperUser]: UserPermission.Edit,
            [UserRole.EntityOwner]: UserPermission.Edit,
          },
          UserPermission.Hidden
        ),
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "saved_count",
        label: "Saved Content Count",
        computed: true,
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "group_users",
        label: "Groups",
        relatedModel: "GroupUser",
        foreignProperty: "user",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "licenses",
        label: "Licenses",
        relatedModel: "License",
        foreignProperty: "user",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "curated_contents",
        label: "Curated Content",
        relatedModel: "Content",
        foreignProperty: "curator",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "authored_external_activities",
        label: "Authored External Activities",
        relatedModel: "ExternalActivity",
        foreignProperty: "author",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "external_activities",
        label: "External Activities",
        relatedModel: "ExternalActivity",
        foreignProperty: "users",
        computed: true,
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "content_engagements",
        label: "Content Engagement",
        relatedModel: "ContentEngagement",
        foreignProperty: "user",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "saved_contents",
        label: "Saved Content",
        relatedModel: "Content",
        foreignProperty: "saved_by",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "credits",
        label: "All Credits",
        relatedModel: "Credit",
        foreignProperty: "user",
      },
    },
  ];
  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    { type: NumberField, opts: { property: "legacy_id" } },
    { type: DateTimeField, opts: { property: "created_at" } },
    { type: DateTimeField, opts: { property: "updated_at" } },
    { type: BooleanField, opts: { property: "is_admin" } },
    { type: BooleanField, opts: { property: "is_group_admin" } },
    {
      type: StringField,
      opts: { property: "first_name", isPrimaryLabelField: 1 },
    },
    {
      type: StringField,
      opts: { property: "last_name", isPrimaryLabelField: 2 },
    },
    { type: EmailField, opts: { property: "email" } },
    { type: EmailField, opts: { property: "personal_email" } },
    { type: "PhoneField", opts: { property: "phone" } },
    { type: StringField, opts: { property: "password" } },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "curated_contents",
        viewComponentName: "view-relationship-field-many-labels",
        formComponentName: "form-relationship-field-many-table",
        conditional: {
          operator: ConditionalOperator.OR,
          conditionals: [
            {
              property: "is_admin",
              compare: "equals",
              value: "1",
            },
            {
              property: "is_group_admin",
              compare: "equals",
              value: "1",
            },
          ],
        },
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "content_engagements",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        // conditional: {
        //   property: "is_admin",
        //   compare: "empty",
        // },
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "external_activities",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        // conditional: {
        //   property: "is_admin",
        //   compare: "empty",
        // },
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "saved_contents",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        // conditional: {
        //   property: "is_admin",
        //   compare: "empty",
        // },
      },
    },
    // {
    //   type: RelationshipFieldMany,
    //   opts: {
    //     name: "browse_saved_contents",
    //     property: "saved_contents",
    //     viewComponentName: "content-browser-field",
    //     // conditional: {
    //     //   property: "is_admin",
    //     //   compare: "empty",
    //     // },
    //   },
    // },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "group_users",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutOpts: {
          enableSearch: false,
          noRefresh: true,
          // inlineEdit: true,
          newLabel: "Add to Existing Group",
          enableDynamicFilters: false,
          collectionActionComponents: [""],
        },
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "licenses",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutOpts: {
          enableSearch: false,
          noRefresh: true,
          inlineEdit: false,
          enableDynamicFilters: false,
          collectionActionComponents: [""],
        },
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "credits",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutOpts: {
          enableSearch: false,
          noRefresh: true,
          enableDynamicFilters: false,
          collectionActionComponents: [""],
        },
      },
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      type: "TabbedModelLayout",
      opts: {
        id: "tabs",
        tabs: [
          {
            id: "overviewTab",
            label: "Overview",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "basic",
                    fields: ["first_name", "last_name", "email", "phone"],
                  },
                  {
                    id: "licenses",
                    fields: ["licenses"],
                  },
                ],
              },
            },
          },
          {
            id: "creditsTab",
            label: "Credits",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "savedcontents",
                    fields: ["credits", "external_activities"],
                  },
                ],
              },
            },
          },
          // {
          //   id: "contentTab",
          //   label: "Library Content",
          //   content: {
          //     type: "FieldSetGridLayout",
          //     opts: {
          //       fieldSets: [
          //         {
          //           id: "savedcontents",
          //           fields: ["content_engagements", "saved_contents"],
          //         },
          //       ],
          //     },
          //   },
          // },
          {
            id: "groupsTab",
            label: "Groups",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "groups",
                    fields: ["group_users"],
                  },
                ],
              },
            },
          },
          {
            id: "accountTab",
            label: "Account Settings",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "groups",
                    fields: ["password", "email"],
                  },
                  {
                    id: "meta",
                    fields: [
                      "is_admin",
                      "is_group_admin",
                      "created_at",
                      "updated_at",
                      "id",
                    ],
                  },
                ],
              },
            },
          },
        ],
      },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      columnFields: ["first_name", "last_name", "email", "created_at"],
      useModal: true,
      navigateForNew: true,
      enableDynamicFilters: true,
      enableSearch: true,
      dynamicFilterOptions: {
        filterFields: [
          "first_name",
          "last_name",
          "email",
          "phone",
          "is_admin",
          "is_group_admin",
          "created_at",
          "updated_at",
          "group_users",
          "group_users.group",
          "content_engagements",
          "content_engagements.content",
          "content_engagements.content.creator",
        ],
      },
      defaultSort: [
        {
          field: "last_name",
          order: "asc",
        },
      ],
    },
  ];
}
