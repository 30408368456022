
import Vue from "vue";
import { FieldGroupField } from "../../field-types/FieldGroupField";

export default {
  data() {
    return {
      reactiveField: null,
    };
  },
  props: {
    field: {
      type: FieldGroupField,
      required: true,
    },
    inputProps: {
      type: Object,
      default: () => ({}),
    },
    readMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    enabledSubFields() {
      return this.field.subFields.filter((subField) => subField.isEnabled);
    },
    scootUpField() {
      return this.$config.useDetachedFieldLabels;
    },
  },
  created() {
    // Wrap the incoming TS class instance with Vue.observable
    this.reactiveField = Vue.observable(this.field);
  },
  watch: {
    // If the field prop ever changes, re-wrap it.
    field(newField) {
      this.reactiveField = Vue.observable(newField);
    },
  },
};
