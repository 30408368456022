import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import {
  DateTimeField,
  NumberField,
  RelationshipField,
  RelationshipFieldMany,
  StringField,
} from "../plugins/Cruxtify/field-types";

import {
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
} from "../plugins/Cruxtify/property-types";
import { IppyDippyCrudModel } from "./IppyDippyCrudModel";

export class Credit extends IppyDippyCrudModel {
  public static icon = "mdi-text-box";
  protected static _typeLabel = "Credit";
  protected static asProperty = "credit";
  public static api = {
    path: "credits",
  };
  protected static routeBase = "credits";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.SuperUser]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
    },
    UserPermission.New
  );

  protected userIdField = ["entry_author"];

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        displayRelative: true,
        label: "Credit Logged At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        displayRelative: true,
        label: "Entry Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "deleted_at",
        displayRelative: true,
        label: "Entry Deleted At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: "DateProperty",
      opts: {
        name: "earned_at",
        label: "Date Earned",
        rules: ["required"],
      },
    },

    {
      type: RelationshipProperty,
      opts: {
        name: "content",
        label: "Content",
        relatedModel: "Content",
        description:
          "The content library content that this credit is associated with (if applicable).",
        computed: true,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "external_activity",
        label: "External Activity",
        relatedModel: "ExternalActivity",
        description:
          "The external activity that this credit is associated with (if applicable).",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Read,
          },
          UserPermission.Hidden
        ),
        computed: true,
        foreignProperty: "credits",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "content_engagement",
        label: "Content Engagement",
        relatedModel: "ContentEngagement",
        description:
          "The engagement data for the content library content this credit is associated with (if applicable).",
        computed: true,
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: "StringProperty",
      opts: {
        name: "label",
        label: "Label",
        rules: ["required"],
      },
    },
    {
      type: "StringProperty",
      opts: {
        name: "description",
        label: "Description",
      },
    },
    {
      type: "NumberProperty",
      opts: {
        name: "credit_hours",
        label: "Credit Hours",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "user",
        label: "User",
        relatedModel: "User",
        description: "The user to whom this credit was granted.",
        foreignProperty: "credits",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
            [UserRole.SuperUser]: UserPermission.Edit,
          },
          UserPermission.Hidden
        ),
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "attachments",
        label: "Attachments",
        relatedModel: "Attachment",
        foreignProperty: "credit",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "entry_author",
        label: "Logged By",
        relatedModel: "User",
        description: "The user who logged this credit (if applicable).",
        foreignProperty: "credits",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Read
        ),
      },
    },
    {
      type: "RelationshipPropertyMany",
      opts: {
        name: "licenses",
        label: "Toward Licenses",
        relatedModel: "License",
        foreignProperty: "credits",
        computed: true,
        description:
          "The licenses that this credit counts toward, based on the credit's \"Date Earned\" and the licenses' start and end dates.",
      },
    },
    {
      type: "RelationshipPropertyMany",
      opts: {
        name: "content_categories",
        label: "Categories",
        relatedModel: "ContentCategory",
        foreignProperty: "credits",
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    {
      type: DateTimeField,
      opts: { property: "created_at" },
    },
    { type: DateTimeField, opts: { property: "updated_at" } },
    { type: DateTimeField, opts: { property: "deleted_at" } },
    { type: "DateField", opts: { property: "earned_at" } },
    { type: RelationshipField, opts: { property: "content" } },
    { type: RelationshipField, opts: { property: "content_engagement" } },
    { type: RelationshipField, opts: { property: "external_activity" } },
    { type: StringField, opts: { property: "label", isPrimaryLabelField: 1 } },
    { type: StringField, opts: { property: "description" } },
    { type: NumberField, opts: { property: "credit_hours" } },
    { type: RelationshipField, opts: { property: "user" } },
    { type: RelationshipField, opts: { property: "entry_author" } },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "attachments",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutOpts: {
          enableSearch: false,
          noRefresh: true,
          enableDynamicFilters: false,
          collectionActionComponents: [""],
        },
      },
    },
    { type: RelationshipFieldMany, opts: { property: "content_categories" } },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "licenses",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutOpts: {
          enableSearch: false,
          noRefresh: true,
          enableDynamicFilters: false,
          collectionActionComponents: [""],
        },
      },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      useModal: true,
      columnFields: [
        "label",
        "credit_hours",
        "user",
        "earned_at",
        "entry_author",
        "created_at",
      ],
      enableDynamicFilters: true,
      enableSearch: true,
      dynamicFilterOptions: {
        filterFields: [
          "content",
          "content_engagement.content",
          "external_activity",
          "label",
          "licenses",
          "credit_hours",
          "earned_at",
          "user",
          "entry_author",
          "created_at",
          "updated_at",
          "description",
          "content_categories",
        ],
      },
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
      fieldSets: [
        {
          id: "0",
          fields: [
            "user",
            "earned_at",
            "label",
            "description",
            "credit_hours",
            "content_categories",
            "content",
            "content_engagement",
            "external_activity",
          ],
        },
        {
          id: "1",
          fields: ["attachments", "licenses"],
        },
        {
          id: "2",
          fields: ["created_at", "updated_at", "entry_author"],
        },
      ],
    },
  ];
}
