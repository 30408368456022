
export default {
  props: {
    fields: {
      type: Array,
      required: true,
    },
    fieldProps: {
      type: Object,
      default: () => ({}),
    },
    rowClass: {
      type: [Object, String],
      default: "",
    },
    colClass: {
      type: [Object, String],
      default: "",
    },
  },
  computed: {
    enabledFields() {
      return this.fields.filter(
        (field) => field.isEnabled && field.isVisibleToUser()
      );
    },
  },
};
