import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

import { IppyDippyCrudModel } from "./IppyDippyCrudModel";

export class License extends IppyDippyCrudModel {
  public static icon = "mdi-license";
  protected static _typeLabel = "License";
  protected static _typeLabelPlural = "Licenses";
  protected static asProperty = "licenses";
  public static api = {
    path: "licenses",
  };
  protected static routeBase = "licenses";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
    },
    UserPermission.New
  );

  protected userIdField = [];

  protected static propertyDefinitions = [
    {
      type: "NumberProperty",
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: "DateTimeProperty",
      opts: {
        name: "created_at",
        displayRelative: true,
        label: "Entry Created At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: "DateTimeProperty",
      opts: {
        name: "updated_at",
        displayRelative: true,
        label: "Entry Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: "DateTimeProperty",
      opts: {
        name: "deleted_at",
        displayRelative: true,
        label: "Entry Deleted At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: "StringProperty",
      opts: {
        name: "label",
        label: "License Label",
        rules: ["required"],
      },
    },
    {
      type: "StringProperty",
      opts: {
        name: "educator_id",
        label: "Educator ID",
      },
    },
    {
      type: "StringProperty",
      opts: {
        name: "description",
        label: "Description",
      },
    },
    {
      type: "DateProperty",
      opts: {
        name: "start_date",
        label: "Start Date",
      },
    },
    {
      type: "DateProperty",
      opts: {
        name: "end_date",
        label: "End Date",
      },
    },
    {
      type: "NumberProperty",
      opts: {
        name: "target_credit_hours",
        label: "Target Credit Hours",
      },
    },
    {
      type: "NumberProperty",
      opts: {
        name: "cache_total_credit_hours",
        label: "Total Credit Hours",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: "NumberProperty",
      opts: {
        name: "cache_benchmark_progress_credit_hours",
        label: "Benchmark Target Credit Hours",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: "PercentProperty",
      opts: {
        name: "cache_benchmark_progress_percent",
        label: "Benchmark Target Progress",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: "PercentProperty",
      opts: {
        name: "cache_current_progress",
        label: "Progress",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: "NumberProperty",
      opts: {
        name: "cache_benchmark_deviation",
        label: "Benchmark Deviation",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: "JsonProperty",
      opts: {
        name: "cache_categories_percentages",
        label: "Category Breakdown",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: "RelationshipProperty",
      opts: {
        name: "user",
        label: "User",
        relatedModel: "User",
        sortable: true,
      },
    },
    {
      type: "RelationshipPropertyMany",
      opts: {
        name: "credits",
        label: "Credits",
        relatedModel: "Credit",
        foreignProperty: "licenses",
      },
    },
    {
      type: "RelationshipPropertyMany",
      opts: {
        name: "attachments",
        label: "Attachments",
        relatedModel: "Attachment",
        foreignProperty: "license",
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: "NumberField", opts: { property: "id" } },
    {
      type: "DateTimeField",
      opts: { property: "created_at" },
    },
    { type: "DateTimeField", opts: { property: "updated_at" } },
    { type: "DateTimeField", opts: { property: "deleted_at" } },
    {
      type: "StringField",
      opts: { property: "label", isPrimaryLabelField: 1 },
    },
    {
      type: "StringField",
      opts: { property: "educator_id", isPrimaryLabelField: 2 },
    },
    {
      type: "LongTextField",
      opts: { property: "description" },
    },
    {
      type: "DateField",
      opts: { property: "start_date" },
    },
    {
      type: "DateField",
      opts: { property: "end_date" },
    },
    {
      type: "NumberField",
      opts: { property: "target_credit_hours" },
    },
    {
      type: "NumberField",
      opts: { property: "cache_total_credit_hours" },
    },
    {
      type: "NumberField",
      opts: { property: "cache_benchmark_progress_credit_hours" },
    },
    {
      type: "NumberField",
      opts: { property: "cache_benchmark_progress_percent" },
    },
    {
      type: "NumberField",
      opts: {
        id: "cache_current_progress_number",
        property: "cache_current_progress",
      },
    },
    {
      type: "NumberField",
      opts: { property: "cache_benchmark_deviation" },
    },
    {
      type: "StringField",
      opts: {
        property: "cache_categories_percentages",
        cols: 12,
        viewComponentName: "license-progress-bar",
        formComponentName: "license-progress-bar",
      },
    },
    {
      type: "StringField",
      opts: {
        id: "cache_current_progress",
        label: "Progress",
        property: "cache_current_progress",
        viewComponentName: "license-progress-bar-small",
        formComponentName: "license-progress-bar-small",
      },
    },
    {
      type: "RelationshipField",
      opts: {
        property: "user",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
            [UserRole.SuperUser]: UserPermission.Read,
          },
          UserPermission.Hidden
        ),
      },
    },
    {
      type: "RelationshipFieldMany",
      opts: {
        property: "credits",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutOpts: {
          enableSearch: true,
          noRefresh: true,
          enableDynamicFilters: false,
          collectionActionComponents: [""],
          navigateForNew: true,
        },
      },
    },
    {
      type: "RelationshipFieldMany",
      opts: {
        property: "attachments",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",

        collectionLayoutOpts: {
          enableSearch: false,
          noRefresh: true,
          enableDynamicFilters: false,
          collectionActionComponents: [""],
        },
      },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      columnFields: [
        "label",
        "educator_id",
        "user",
        "cache_current_progress",
        "target_credit_hours",
        "cache_benchmark_deviation",
        "start_date",
        "end_date",
      ],
      enableSearch: true,
      enableDynamicFilters: true,
      useModal: true,
      dynamicFilterOptions: {
        filterFields: [
          "label",
          "educator_id",
          "description",
          "start_date",
          "end_date",
          "user",
          "user.group_users",
          "user.group_users.group",
          "cache_total_credit_hours",
          "target_credit_hours",
          "created_at",
          "updated_at",
          "cache_current_progress",
          "cache_benchmark_progress_percent",
          "cache_benchmark_progress_credit_hours",
          "cache_benchmark_deviation",
        ],
      },
      defaultSort: [
        {
          field: "end_date",
          order: "desc",
        },
      ],
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      type: "TabbedModelLayout",
      opts: {
        id: "tabs",
        tabs: [
          {
            id: "overviewTab",
            label: "Overview",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                id: "Crudform",
                fieldSets: [
                  {
                    id: "1",
                    fields: [
                      "cache_categories_percentages",
                      "target_credit_hours",
                      "cache_total_credit_hours",
                      "cache_current_progress_number",
                      "cache_benchmark_progress_percent",
                      "cache_benchmark_progress_credit_hours",
                      "cache_benchmark_deviation",
                    ],
                  },
                  {
                    id: "0",
                    fields: [
                      "label",
                      "educator_id",
                      "start_date",
                      "end_date",
                      "user",
                      "description",
                      "attachments",
                    ],
                  },
                  {
                    id: "meta",
                    fields: ["created_at", "updated_at", "id"],
                  },
                ],
              },
            },
          },
          {
            id: "creditsTab",
            label: "Credits",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "1",
                    fields: ["credits"],
                  },
                ],
              },
            },
          },
        ],
      },
    },
  ];
}
