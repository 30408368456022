
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      clipped: false,
      drawer: true,
      fixed: false,
      accountLoading: false,
      accountMenuItems: [],
      allPrimaryMenuItems: [
        {
          icon: "mdi-play-box-multiple-outline",
          title: "Browse Content",
          id: "browse-content",
          to: "/browse-content",
        },
        {
          icon: "mdi-play-box-multiple-outline",
          title: "License",
          id: "license",
          to: () => {
            return this.userSingleLicense
              ? `/licenses/${this.userSingleLicense}`
              : "/licenses";
          },
          visibility: ["nonAdminOnly"],
        },

        {
          icon: "mdi-account",
          title: "Users",
          id: "users",
          to: "/users",
          visibility: ["super"],
        },
        {
          icon: "mdi-license",
          title: "Licenses",
          id: "licenses",
          to: "/licenses",
          visibility: ["super"],
        },
        {
          icon: "mdi-text-box",
          title: "Credits",
          id: "credits",
          to: "/credits",
        },
        // {
        //   icon: "mdi-attachment",
        //   title: "Attachments",
        //   id: "attachments",
        //   to: "/attachments",
        //   visibility: ["super"],
        // },
        {
          icon: "mdi-account-group",
          title: "Groups",
          id: "groups",
          to: "/groups",
          visibility: ["super"],
        },
        {
          icon: "mdi-account-group",
          title: "External Activities",
          id: "external-activities",
          to: "/external-activities",
          visibility: ["super"],
        },
        // {
        //   icon: "mdi-play-box-multiple-outline",
        //   title: "Group Content",
        //   id: "group-content",
        //   to: "/group-content",
        //   visibility: ["super"],
        // },
        {
          icon: "mdi-table-of-contents",
          title: "Content Library",
          id: "content-library",
          to: "/contents",
          visibility: ["admin"],
          subItems: [
            {
              icon: "mdi-table-of-contents",
              title: "Content",
              id: "contents",
              to: "/contents",
              visibility: ["admin"],
            },
            {
              icon: "mdi-creation",
              title: "Creators",
              id: "creators",
              to: "/creators",
              visibility: ["admin"],
            },
            {
              icon: "mdi-head-question",
              title: "Quiz Questions",
              id: "quiz-questions",
              to: "/quiz-questions",
              visibility: ["admin"],
            },
            {
              icon: "mdi-shape",
              title: "Categories",
              id: "categories",
              to: "/content-categories",
            },
            {
              icon: "mdi-signal",
              title: "Classroom Levels",
              id: "classroom-levels",
              to: "/classroom-levels",
              visibility: ["admin"],
            },
            {
              icon: "mdi-multimedia",
              title: "Media Types",
              id: "media-types",
              to: "/media-types",
            },
          ],
        },
        {
          title: "My Account",
          icon: "mdi-account",
          id: "my-account",
          to: "/my-account",
          subItems: [
            {
              title: "Account Settings",
              id: "account-settings",
              to: "/my-account",
            },
            {
              icon: "mdi-exit-to-app",
              title: "Logout",
              id: "logout",
              click: function () {
                this.accountLoading = true;
                this.$auth.logout().then(() => {
                  this.accountLoading = false;
                  window.location.href = "/login";
                });
              }.bind(this),
            },
          ],
        },
      ],
      miniVariant: false,
      enableIcons: false,
    };
  },
  mounted() {
    if (typeof this.$route.query.debug !== "undefined") {
      this.$config.debug = this.$route.query.debug == "1" ? true : false;
    }
  },
  computed: {
    ...mapGetters("app", ["title"]),
    activePrimaryMenuItems() {
      return this.allPrimaryMenuItems.filter((item) =>
        this.showItemForCurUser(item, this.$auth.user)
      );
    },
    activeAccountMenuItems() {
      return this.accountMenuItems.filter((item) =>
        this.showItemForCurUser(item, this.$auth.user)
      );
    },
    activeSecondaryMenuItems() {
      return this.allSecondaryMenuItems.filter((item) =>
        this.showItemForCurUser(item, this.$auth.user)
      );
    },
    mobileMenuItems() {
      return this.activePrimaryMenuItems
        .concat(this.activeAccountMenuItems)
        .map((item) => {
          if (item.subItems) {
            return [{ br: true }, ...item.subItems];
          }
          return item;
        })
        .flat();
    },
    userName() {
      if (!this.$auth.loggedIn) return "";
      return this.$auth.user.first_name;
    },
    showSystemMenu() {
      return this.$auth.user.is_admin;
    },
    userSingleLicense() {
      if (!this.$auth.loggedIn) return "";
      return this.$auth.user.licenses && this.$auth.user.licenses.length === 1
        ? this.$auth.user.licenses[0].id
        : false;
    },
    savedCount() {
      return this.$auth.user.save_count || 0;
    },
    menuStyleBreakpoint() {
      if (this.activePrimaryMenuItems.length < 4) {
        return "xsOnly";
      } else if (this.activePrimaryMenuItems.length < 6) {
        return "smAndDown";
      }

      return "mdAndDown";
    },
    desktopMenuClass() {
      return {
        "d-none": this.$vuetify.breakpoint[this.menuStyleBreakpoint],
      };
    },
    mobileMenuClass() {
      return {
        "d-none": !this.desktopMenuClass["d-none"],
      };
    },
  },
  methods: {
    decorateTo(to) {
      if (to && to.indexOf("{current_user}") !== -1) {
        to = to.replace("{current_user}", this.$auth.user.id);
      }
      return to;
    },
    showItemForCurUser: function (item, user) {
      if (!item.visibility) return true;

      if (typeof item.visibility == "function") return item.visibility(user);

      const isNonAdmin = item.visibility.indexOf("nonAdminOnly") !== -1;

      if (user.is_admin) return !isNonAdmin;

      if (user.is_super_user)
        return item.visibility.indexOf("super") !== -1 && !isNonAdmin;

      return (
        item.visibility.indexOf("admin") === -1 &&
        item.visibility.indexOf("super") === -1
      );
    },
  },
};
