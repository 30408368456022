import { CrudModel } from "../CrudModel";
import { CrudProperty, ICrudProperty } from "../CrudProperty";

export interface IStringProperty extends ICrudProperty {
  maxLength?: number;
}

export class StringProperty extends CrudProperty {
  protected val?: string;
  public maxLength: number = 0;

  constructor(opts: IStringProperty, model?: CrudModel) {
    super(opts, model);

    // defaults
    if (typeof opts.sortable === "undefined") this.sortable = true;

    if (typeof opts.maxLength !== "undefined") this.maxLength = opts.maxLength;
  }

  public get isEmpty() {
    return !this.value;
  }
}
