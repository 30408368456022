import { isNull } from "lodash";
import { Conditional, IConditional } from "./Conditional";
import { CrudModel, HasFields } from "./CrudModel";
import { CrudProperty, CrudPropertyGet } from "./CrudProperty";
import Rule, { IUseRule } from "./Rule";
import {
  HasUserPermissions,
  UserPermission,
  UserPermissions,
} from "./UserPermission";

export type CrudFieldQuery = CrudField | string;
export interface ICrudField {
  id?: string;
  property?: string | CrudProperty;
  label?: string;
  requiresDetachedLabel?: boolean;
  icon?: string;
  description?: string | null;
  isPrimaryLabelField?: boolean | number;
  formComponentName?: string;
  formComponentProps?: Record<string, any>;
  viewComponentName?: string;
  viewComponentProps?: Record<string, any>;
  afterLabelComponents?: string[];
  userPermissions?: UserPermissions | UserPermission;
  rules?: IUseRule[];
  disableRules?: IUseRule[] | boolean;
  default?: any;
  computed?: boolean;
  fieldWidth?: IFieldWidthValues;
  isEnabled?: boolean;
  isOrphan?: boolean;
  conditional?: IConditional;
  xl?: number | string;
  lg?: number | string;
  md?: number | string;
  sm?: number | string;
  cols?: number | string;
}
// export type IFieldWidthValues = "full" | "large" | "medium" | "small" | "tiny";
export type IFieldWidthValues = string;
export type CrudFieldType = typeof CrudField;
export type ICrudFieldType = CrudFieldType | string;
export interface ICrudFieldDefinition {
  type: ICrudFieldType;
  opts: ICrudField;
}

export class CrudField implements HasFields, HasUserPermissions {
  public static fieldName = "CrudField";
  public get fieldName() {
    return this._classRef.fieldName;
  }
  protected _label: string | undefined;
  public get label() {
    return typeof this._label !== "undefined"
      ? this._label
      : this.property.label;
  }

  public isOfType(typeArg: ICrudFieldType) {
    const type = this._classRef.getCrudFieldType(typeArg);
    return this._classRef === type;
  }
  public static isOfType(typeArg: ICrudFieldType) {
    const type = this.getCrudFieldType(typeArg);
    return this === type;
  }

  protected _requiresDetachedLabel: boolean | null = null;
  protected static requiresDetachedLabel: boolean = false;
  public get requiresDetachedLabel() {
    return isNull(this._requiresDetachedLabel)
      ? this._classRef.requiresDetachedLabel
      : this._requiresDetachedLabel;
  }

  protected _icon: string = "";
  protected static icon: string = "";
  public get icon() {
    return this._icon ? this._icon : this._classRef.icon;
  }

  protected _description: string | null = "";
  public get description() {
    return this._description || this._description === null
      ? this._description
      : this.property.description;
  }
  public isPrimaryLabelField: boolean | number = false;

  public get isRemotelyComputed() {
    return this.property.isRemotelyComputed;
  }

  public get isEmpty() {
    return this.property.isEmpty;
  }

  public fieldWidth: IFieldWidthValues = "medium";
  public cols: number | string = "auto";
  public sm?: number | string;
  public md?: number | string;
  public lg?: number | string;
  public xl?: number | string;

  protected _id: string = "";
  public get id(): string {
    if (!this._id) {
      this._id = this.property.name;
    }
    return this._id;
  }

  public _rules: IUseRule[] = [];
  public get rules() {
    if (this.disableRules === true) return [];

    const propRules =
      this.property && this.property.rules ? this.property.rules : [];

    return [...propRules, ...this._rules].filter(
      (rule) =>
        !this.disableRules || !(this.disableRules as IUseRule[]).includes(rule)
    );
  }

  protected disableRules: IUseRule[] | boolean = [];

  protected _default: any;
  public get default(): any {
    return typeof this._default !== "undefined"
      ? this._default
      : this.property.default;
  }

  public get isRequired(): boolean {
    return Rule.rulesIncludes(this.rules, "required");
  }

  // "enabled" generally means visible and not ignored for things like validation
  private _isEnabled: boolean = true;
  public get isEnabled(): boolean {
    if (!this._conditional) return this._isEnabled;

    return this._conditional.result;
  }

  protected static readonly defaultPropertyType = CrudProperty;
  protected _property: CrudProperty;
  public get property(): CrudProperty {
    return this._property;
  }

  public get value() {
    return this.property.value;
  }

  public set value(val) {
    this.property.set(val);
  }

  public get stringValue() {
    return this.get();
  }

  protected _isOrphan: boolean = false;
  public get isOrphan() {
    return this._isOrphan;
  }

  public get hasUnsavedChanges() {
    // deprecated
    return this.property.hasUnsavedChanges;
  }

  public isTouched: boolean = false;

  protected _userPermissions?: UserPermissions;
  protected get userPermissions(): UserPermissions {
    if (typeof this._userPermissions === "undefined")
      this._userPermissions = this.property.userPermissions;
    return this._userPermissions;
  }
  protected set userPermissions(value) {
    this._userPermissions = value;
  }

  // TODO: refactor this factory stuff (also in CrudModel, Conditional,
  // CollectionLayout, etc) into a generic or decorator or mixin or something
  private static _crudModelFieldTypes: Record<string, ICrudFieldType> = {};
  public static getCrudFieldType(type: ICrudFieldType): CrudFieldType {
    if (typeof type !== "string") return type as CrudFieldType;
    if (!this._crudModelFieldTypes[type])
      throw new Error("CrudFieldType not registered: " + type);

    return this._crudModelFieldTypes[type] as CrudFieldType;
  }
  public static registerCrudFieldType(name: string, type: ICrudFieldType) {
    this._crudModelFieldTypes[name] = type;
  }
  public static registerCrudFieldTypes(
    registrationMap: Record<string, ICrudFieldType> = {}
  ) {
    Object.keys(registrationMap).forEach((name: string) =>
      this.registerCrudFieldType(name, registrationMap[name] as CrudFieldType)
    );
  }

  // factory method
  public static newInstance(
    def: ICrudFieldDefinition,
    model?: CrudModel
  ): CrudField {
    return new (this.getCrudFieldType(def.type))(def.opts, model);
  }
  public static newInstances(
    defs: ICrudFieldDefinition[] = [],
    model?: CrudModel
  ) {
    return defs.map((fieldDef) => this.newInstance(fieldDef, model));
  }

  public clone(newOpts?: Partial<ICrudField>): CrudField {
    const newInstanceOpts = {
      ...this._opts,
      property: this.property.clone(),
      ...newOpts,
    };
    return new (this.constructor as CrudFieldType)(newInstanceOpts, this.model);
  }

  public isVisibleToUser(user?, model?: CrudModel) {
    if (!user && this.$nuxt.$auth.loggedIn) user = this.$nuxt.$auth.user;

    if (this.isOrphan) return true;

    // if field has explicitly-set userPermissions, use those
    if (this._userPermissions)
      return this.userPermissions.isVisibleToUser(this.model, user);

    return (
      this.property.isVisibleToUser(user, model) &&
      this.userPermissions.isVisibleToUser(this.model, user)
    );
  }

  public get isReadonly() {
    return this.property.isReadonly;
  }

  public isReadonlyToUser(user?, model?: CrudModel) {
    if (this.isOrphan) return false;

    if (this.isRemotelyComputed) return true;

    if (!user && this.$nuxt.$auth.loggedIn) user = this.$nuxt.$auth.user;

    // if field has explicitly-set userPermissions, use those
    if (this._userPermissions)
      return this.userPermissions.isReadonlyToUser(this.model, user);

    return (
      this.property.isReadonlyToUser(user, model) ||
      this.userPermissions.isReadonlyToUser(this.model, user)
    );
  }

  public get model(): CrudModel | undefined {
    return this.property.model;
  }

  public _formComponentName: string = "";
  public static formComponentName: string = "";

  public _formComponentProps: Record<string, any> = {};
  public static formComponentProps = {};

  public _viewComponentName: string = "";
  public static viewComponentName: string = "ViewReadOnly";

  public _viewComponentProps: Record<string, any> = {};
  public static viewComponentProps = {};

  public _afterLabelComponents: string[] = [];
  public static afterLabelComponents: string[] = [];
  public get afterLabelComponents(): string[] {
    return [
      ...this._afterLabelComponents,
      ...this._classRef.afterLabelComponents,
    ];
  }

  protected _conditional: Conditional | undefined;

  protected _opts: ICrudField;

  constructor(opts: ICrudField, model?: CrudModel) {
    this._property = <CrudProperty>CrudProperty.findOrCreateInstance({
      type: this._classRef.defaultPropertyType,
      prop: opts.property,
      model,
    });

    this._opts = opts;

    if (typeof opts.id !== "undefined") this._id = opts.id;

    if (typeof opts.label !== "undefined") this._label = opts.label;

    if (typeof opts.requiresDetachedLabel !== "undefined")
      this._requiresDetachedLabel = opts.requiresDetachedLabel;

    if (typeof opts.icon !== "undefined") this._icon = opts.icon;

    if (typeof opts.afterLabelComponents !== "undefined")
      this._afterLabelComponents = opts.afterLabelComponents;

    if (
      this.$nuxt.$config.debug &&
      !this._afterLabelComponents.includes("field-debugger")
    )
      this._afterLabelComponents.push("field-debugger");

    if (typeof opts.isPrimaryLabelField !== "undefined")
      this.isPrimaryLabelField = opts.isPrimaryLabelField;

    if (typeof opts.rules !== "undefined") this._rules = opts.rules;

    if (typeof opts.disableRules !== "undefined")
      this.disableRules = opts.disableRules;

    if (typeof opts.default !== "undefined") this._default = opts.default;

    if (typeof opts.formComponentName !== "undefined")
      this._formComponentName = opts.formComponentName;

    if (typeof opts.viewComponentName !== "undefined")
      this._viewComponentName = opts.viewComponentName;

    if (typeof opts.formComponentProps !== "undefined")
      this._formComponentProps = opts.formComponentProps;

    if (typeof opts.viewComponentProps !== "undefined")
      this._viewComponentProps = opts.viewComponentProps;

    if (typeof opts.isOrphan !== "undefined") this._isOrphan = opts.isOrphan;

    if (typeof opts.fieldWidth !== "undefined")
      this.fieldWidth = opts.fieldWidth;

    if (typeof opts.cols !== "undefined") this.cols = opts.cols;
    if (typeof opts.sm !== "undefined") this.sm = opts.sm;
    if (typeof opts.md !== "undefined") this.md = opts.md;
    if (typeof opts.lg !== "undefined") this.lg = opts.lg;
    if (typeof opts.xl !== "undefined") this.xl = opts.xl;

    if (typeof opts.userPermissions !== "undefined")
      this.userPermissions =
        typeof opts.userPermissions === "object"
          ? opts.userPermissions
          : new UserPermissions(opts.userPermissions);

    if (typeof opts.computed !== "undefined") {
      console.log(
        "computed prop on field is deprecated. move it to the property."
      );
    }

    if (typeof opts.description !== "undefined")
      this._description = opts.description;

    // we also check for model existence because conditionals can't work without it
    // TODO: smelly
    if (typeof opts.conditional !== "undefined" && this.model)
      this._conditional = Conditional.newInstance(opts.conditional, this);

    if (typeof opts.isEnabled !== "undefined") this._isEnabled = opts.isEnabled;

    this.maybeSetDefault();
  }

  public get hasDefault(): boolean {
    return typeof this._default !== "undefined";
  }

  public maybeSetDefault() {
    if (this.hasDefault && this.default !== null && this.property.isEmpty) {
      const value =
        typeof this.default === "function" ? this.default(this) : this.default;

      if (typeof value !== undefined && !isNull(value)) this.set(value);
    }
  }

  protected get _classRef(): typeof CrudField {
    return Object.getPrototypeOf(this).constructor;
  }

  protected static $nuxt;
  public static setNuxtContext(context) {
    this.$nuxt = context;
  }
  protected get $nuxt() {
    return this._classRef.$nuxt;
  }

  public get(opts?: CrudPropertyGet) {
    return this.property ? this.property.get(opts) : undefined;
  }

  public get isHydrated() {
    // deprecated
    return this.property.isHydrated;
  }

  public getProperty() {
    // deprecated
    return this.property.name;
  }

  public get serializedPayload() {
    return this.property.serializedPayload;
  }

  public set(val: unknown, skipMarkingAsUnsaved = false) {
    this.property.set(val, skipMarkingAsUnsaved);
  }

  public getField(fieldQuery: CrudFieldQuery): CrudField | undefined {
    // deprecated
    return this.findField(fieldQuery);
  }
  public getFields(
    fieldQueries?: CrudFieldQuery[] | CrudFieldQuery
  ): CrudField[] | undefined {
    // deprecated
    return this.findFields(fieldQueries);
  }

  public findField(fieldQuery: CrudFieldQuery): CrudField | undefined {
    const res = this.findFields(fieldQuery);
    return res ? res.pop() : undefined;
  }
  public findFields(
    fieldQueries?: CrudFieldQuery[] | CrudFieldQuery
  ): CrudField[] | undefined {
    if (!fieldQueries) return [this];
    if (!Array.isArray(fieldQueries)) fieldQueries = [fieldQueries];
    if (!fieldQueries[0]) return [this];

    return fieldQueries
      .filter((fieldQuery) => fieldQuery)
      .some((fieldQuery) =>
        typeof fieldQuery === "string"
          ? this.id == fieldQuery
          : fieldQuery.id == this.id
      )
      ? [this]
      : undefined;
  }

  public getRelativeLabel(fieldQuery: CrudFieldQuery, prefix = "") {
    const field = this.findField(fieldQuery);
    if (!field) return;

    return (prefix ? prefix + " - " : "") + this.label;
  }

  public getFormComponentName(forceFormComponent?: boolean) {
    // deprecated
    if (!forceFormComponent && this.isReadonlyToUser())
      return this.getViewComponentName();
    return this.formComponentName;
  }

  public get formComponentName() {
    return this._formComponentName
      ? this._formComponentName
      : this._classRef.formComponentName;
  }

  public getViewComponentName() {
    // deprecated
    return this.viewComponentName;
  }

  public get viewComponentName() {
    return this._viewComponentName
      ? this._viewComponentName
      : this._classRef.viewComponentName;
  }

  public getFormComponentProps(mergeProps = {}) {
    // deprecated
    return Object.assign(
      {},
      {
        field: this,
      },
      this.formComponentProps,
      mergeProps
    );
  }

  public get formComponentProps() {
    return Object.assign(
      {},
      this._classRef.formComponentProps,
      this._formComponentProps
    );
  }

  public getViewComponentProps(mergeProps = {}) {
    // deprecated
    return Object.assign(
      {},
      {
        field: this,
      },
      this._classRef.viewComponentProps,
      this.viewComponentProps,
      mergeProps
    );
  }

  public get viewComponentProps() {
    return Object.assign(
      {},
      this._classRef.viewComponentProps,
      this._viewComponentProps
    );
  }

  public get componentVuetifyRules() {
    return Rule.getVuetifyRules(this.rules);
  }

  public get isValid() {
    return this.property.isValid && Rule.isValid(this.rules, this.value);
  }

  public validate() {
    return Rule.isValid(this.rules, this.value);
  }

  public get invalidMessages() {
    return Rule.invalidMessages(this.rules, this.value);
  }

  public static fieldsQueryAsString(fieldQuery: CrudFieldQuery) {
    return typeof fieldQuery === "string" ? fieldQuery : fieldQuery.id;
  }

  public save() {
    return this.property.model?.save();
  }
}
