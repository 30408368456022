import {
  CrudCollection,
  ICollectionRemoteQueryOptions,
  ICrudCollection,
  SortObjectArray,
} from "../CrudCollection";
import { CrudField, CrudFieldQuery } from "../CrudField";
import { CrudFilter } from "../CrudFilter";
import {
  CrudLayout,
  CrudLayoutDefinition,
  CrudLayoutOpts,
} from "../CrudLayout";
import { CrudModelType } from "../CrudModel";
import { CrudPropertyQuery } from "../CrudProperty";
import { CrudFieldFilter, CrudPropertyFilter } from "../filters";
import { CrudSearchFilter } from "../filters/CrudSearchFilter";

import _ from "lodash";
import { DynamicFilterOptions } from "../filters/DynamicFilterController";

export interface IModalOptions {
  layoutId?: string;
  readmodeDefault?: boolean;
  title?: string;
}

export interface CollectionLayoutDefinition extends CrudLayoutDefinition {
  columnFields?: CrudFieldQuery[];
  useDynamicFields?: boolean;
  defaultSort?: SortObjectArray;
  remoteQueryOptions?: ICollectionRemoteQueryOptions;
  useModal?: boolean;
  modalOptions: IModalOptions;
  hideStaticFilterFields?: boolean;
  ordinalField?: CrudFieldQuery;
  enabledItemActions?: CollectionLayoutItemActions[];
  footerComponents?: string[];
  headerComponents?: string[];
  collectionActionComponents?: string[];
  itemActionComponents?: string[];
  enableSearch?: boolean;
  searchLabel?: string;
  noneText?: string;
  emphasizeNoneText?: boolean;
  noNew?: boolean;
  navigateForNew?: boolean;
  newLabel?: string;
  noRefresh?: boolean;
  hideWhenEmpty?: boolean;
  enableDynamicFilters?: boolean;
  dynamicFilterOptions?: DynamicFilterOptions;
  disableFields?: CrudFieldQuery[];
  collectionOptions?: Partial<ICrudCollection>;
  xl?: number | string;
  lg?: number | string;
  md?: number | string;
  sm?: number | string;
  cols?: number | string;

  // legacy and kinda deprecated
  filterFields?: CrudFieldQuery[] | null;
  filterProperties?: CrudPropertyQuery[];
  staticPropertyFilters?: StaticFilterDefinition[];
}

export type StaticFilterDefinition = Record<string, any>;

export type CollectionLayoutItemActions = "edit" | "delete";

export interface CollectionLayoutOpts
  extends CrudLayoutOpts,
    CollectionLayoutDefinition {
  modelType?: CrudModelType;
  collection?: CrudCollection;
  filters?: CrudFilter[];
}

export class CollectionLayout extends CrudLayout {
  public modelType: CrudModelType;
  public columnFields: CrudFieldQuery[];
  public disableFields: CrudFieldQuery[] = [];
  public ordinalField: CrudFieldQuery = "";
  public useDynamicFields: boolean = true;
  public filters: CrudFilter[] = [];
  public defaultSort: SortObjectArray = [];
  public remoteQueryOptions: ICollectionRemoteQueryOptions = {};
  public hideStaticFilterFields = true;
  public footerComponents: string[] = [];
  public headerComponents: string[] = [];
  public collectionActionComponents: string[] = [];
  public itemActionComponents: string[] = [];
  public searchLabel: string = "Search";
  public navigateForNew = false;
  public noNew = false;
  public noRefresh = false;
  public newLabel = "Create New";
  public enableSearch = false;
  public useModal = false;
  public emphasizeNoneText = false;
  public hideWhenEmpty = false;
  public enableDynamicFilters = false;
  public modalOptions: IModalOptions = {
    layoutId: "",
    readmodeDefault: true,
  };
  public enabledItemActions: CollectionLayoutItemActions[] = [];
  public inlineEdit = false;
  public inlineEditFields: string[] = [];
  public dynamicFilterOptions: DynamicFilterOptions = {};

  public cols: number | string = "auto";
  public sm?: number | string;
  public md?: number | string;
  public lg?: number | string;
  public xl?: number | string;

  protected _noneText = "";
  public get noneText() {
    return this._noneText;
  }

  protected _collection: CrudCollection | null = null;
  protected _collectionOptions: Partial<ICrudCollection> = {};
  public get collection() {
    if (this._collection === null) {
      const collectionOpts: ICrudCollection = {
        model: this.modelType,
        remoteQueryFilters: this.filters,
        defaultSort: this.defaultSort,
        remoteQueryOptions: this.remoteQueryOptions,
        dynamicFilterOptions: this.dynamicFilterOptions,
        ...this._collectionOptions,
      };

      if (this.ordinalField) {
        // if using an ordinalField, we have to show all items at once
        // and sort by the field
        collectionOpts.remoteQueryOptions = {
          perPage: -1,
          sortBy: [
            (this.modelType.findField(this.ordinalField) as CrudField).property,
          ],
          sortDesc: [0],
        };
      }

      this._collection = new CrudCollection(collectionOpts);
    }

    return this._collection;
  }

  constructor(opts: CollectionLayoutOpts) {
    super(opts);

    if (typeof opts.modelType !== "undefined") this.modelType = opts.modelType;
    else if (typeof opts.collection !== "undefined")
      this.modelType = opts.collection.model;
    else throw new Error("CollectionLayout requires a modelType or collection");

    if (typeof opts.columnFields !== "undefined")
      this.columnFields = opts.columnFields;
    else this.columnFields = this.modelType.getVisibleFields();

    if (typeof opts.inlineEdit !== "undefined")
      this.inlineEdit = opts.inlineEdit;

    if (typeof opts.collectionOptions !== "undefined") {
      if (opts.collection) {
        console.warn(
          "collectionOptions is ignored when collection is provided"
        );
      } else this._collectionOptions = opts.collectionOptions;
    }

    if (typeof opts.inlineEditFields !== "undefined")
      this.inlineEditFields = opts.inlineEditFields;

    if (opts.inlineEdit) this.useDynamicFields = true;

    if (typeof opts.useDynamicFields !== "undefined") {
      if (opts.inlineEdit)
        console.warn("useDynamicFields is ignored when inlineEdit is true");
      else this.useDynamicFields = opts.useDynamicFields;
    } else if (!opts.inlineEdit) {
      this.useDynamicFields =
        this.$nuxt.$config.defaultCollectionUseDynamicFields;
    }

    if (typeof opts.enabledItemActions !== "undefined")
      this.enabledItemActions = opts.enabledItemActions;

    if (typeof opts.itemActionComponents !== "undefined")
      this.itemActionComponents = opts.itemActionComponents;

    if (typeof opts.ordinalField !== "undefined")
      this.ordinalField = opts.ordinalField;

    if (typeof opts.disableFields !== "undefined")
      this.disableFields = opts.disableFields;

    // modal
    if (typeof opts.useModal !== "undefined") this.useModal = opts.useModal;

    // when model doesn't have a route, force modal usage
    if (!this.modelType.getRouteSingle()) this.useModal = true;

    if (typeof opts.modalOptions !== "undefined")
      Object.assign(this.modalOptions, opts.modalOptions);

    // filters
    if (typeof opts.enableDynamicFilters !== "undefined")
      this.enableDynamicFilters = opts.enableDynamicFilters;

    if (typeof opts.dynamicFilterOptions !== "undefined")
      this.dynamicFilterOptions = opts.dynamicFilterOptions;

    if (typeof opts.filters !== "undefined")
      this.filters = [...this.filters, ...opts.filters];

    if (
      typeof opts.filterFields !== "undefined" &&
      opts.filterFields &&
      opts.filterFields.length > 0
    )
      this.filters = [
        ...this.filters,
        ...this.modelType
          .findFields(opts.filterFields)
          .filter((field) => field.isVisibleToUser())
          .map((field) => {
            return CrudFieldFilter.fromField(field);
          }),
      ];

    if (
      typeof opts.filterProperties !== "undefined" &&
      opts.filterProperties.length > 0
    )
      this.filters = [
        ...this.filters,
        ...this.modelType
          .findProperties(opts.filterProperties)
          .map((property) => {
            return CrudPropertyFilter.fromProperty(property);
          }),
      ];

    if (typeof opts.staticPropertyFilters !== "undefined")
      this.filters = [
        ...this.filters,
        ...this.modelType
          .findProperties(Object.keys(opts.staticPropertyFilters))
          .map((property) => {
            const filter = CrudPropertyFilter.fromProperty(property);
            filter.setQuery(opts.staticPropertyFilters![property.name]);
            return filter;
          }),
      ];

    // search filter
    if (typeof opts.enableSearch !== "undefined")
      this.enableSearch = opts.enableSearch;

    if (typeof opts.searchLabel !== "undefined")
      this.searchLabel = opts.searchLabel;

    if (this.enableSearch) {
      const searchFilter = CrudSearchFilter.create(this.searchLabel);

      this.filters.unshift(searchFilter);
    }

    if (typeof opts.defaultSort !== "undefined")
      this.defaultSort = opts.defaultSort;

    if (typeof opts.remoteQueryOptions !== "undefined")
      this.remoteQueryOptions = opts.remoteQueryOptions;

    if (typeof opts.hideStaticFilterFields !== "undefined")
      this.hideStaticFilterFields = opts.hideStaticFilterFields;

    if (typeof opts.footerComponents !== "undefined")
      this.footerComponents = opts.footerComponents;

    if (typeof opts.headerComponents !== "undefined")
      this.headerComponents = opts.headerComponents;

    if (typeof opts.collectionActionComponents !== "undefined")
      this.collectionActionComponents = opts.collectionActionComponents;

    if (typeof opts.noNew !== "undefined") this.noNew = opts.noNew;

    if (typeof opts.newLabel !== "undefined") this.newLabel = opts.newLabel;

    if (typeof opts.navigateForNew !== "undefined")
      this.navigateForNew = opts.navigateForNew;

    if (typeof opts.noRefresh !== "undefined") this.noRefresh = opts.noRefresh;

    if (typeof opts.hideWhenEmpty !== "undefined")
      this.hideWhenEmpty = opts.hideWhenEmpty;

    if (typeof opts.noneText !== "undefined") this._noneText = opts.noneText;

    if (typeof opts.emphasizeNoneText !== "undefined")
      this.emphasizeNoneText = opts.emphasizeNoneText;

    if (typeof opts.cols !== "undefined") this.cols = opts.cols;
    if (typeof opts.sm !== "undefined") this.sm = opts.sm;
    if (typeof opts.md !== "undefined") this.md = opts.md;
    if (typeof opts.lg !== "undefined") this.lg = opts.lg;
    if (typeof opts.xl !== "undefined") this.xl = opts.xl;

    if (typeof opts.collection !== "undefined") {
      this._collection = opts.collection;
      this._collection.useFilters(this.filters, true);
      this._collection.applySortObjectArray(this.defaultSort);

      // if using an ordinalField, we have to show all items at once
      if (this.ordinalField) {
        this._collection.setQueryOption("perPage", -1);

        // sort by field
        this._collection.setQueryOption("sortBy", [
          (this._collection.model.findField(this.ordinalField) as CrudField)
            .property,
        ]);
        this._collection.setQueryOption("sortDesc", [0]);
      }
    }
  }

  public get componentProps(): Record<string, any> {
    return Object.assign({ collection: this.collection }, super.componentProps);
  }

  public newForCollection(
    collection: CrudCollection,
    additionalOpts: Partial<CollectionLayoutOpts> = {}
  ) {
    const opts = _.merge({}, this._opts, additionalOpts);
    opts.collection = collection;

    return new (this.constructor as any)(opts);
  }

  public get modalProps(): Record<string, any> {
    return {
      "model-layout-id": this.modalOptions.layoutId,
      "readmode-default": this.modalOptions.readmodeDefault,
      title: this.modalOptions.title,
    };
  }

  public get perPage() {
    return this.collection.remoteQuery === false
      ? -1
      : this.collection.remoteQueryOptions.perPage;
  }

  public get totalPages() {
    return !this.perPage || this.perPage < 1
      ? 1
      : Math.ceil(this.collection.totalItems / this.perPage);
  }
}
