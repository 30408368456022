
import { isNull } from "lodash";
import { CrudField } from "../CrudField";

export default {
  data() {
    return {};
  },
  props: {
    field: {
      type: CrudField,
      required: true,
    },
    parentReadMode: {
      type: Boolean,
      default: false,
    },
    inputOnly: {
      type: Boolean,
      default: false,
    },
    keepInputLabel: {
      type: Boolean,
      default: false,
    },
    readMode: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    showDetachedLabel() {
      return (
        !this.inputOnly &&
        this.field.label &&
        (this.field.requiresDetachedLabel ||
          this.isReadMode ||
          this.$config.useDetachedFieldLabels)
      );
    },
    inputProps() {
      const fieldProps = !this.isReadMode
        ? this.field.formComponentProps
        : this.field.viewComponentProps;

      return {
        ...fieldProps,

        "prepend-icon": this.field.icon,

        label:
          this.showDetachedLabel || (this.inputOnly && !this.keepInputLabel)
            ? ""
            : this.field.label,
        hasDetachedLabel: this.showDetachedLabel,

        // errors/validation
        // error: this.field.isTouched && !this.field.isValid,
        // "error-messages": this.field.isTouched
        //   ? this.field.invalidMessages
        //   : [],
        // "error-count": this.field.isTouched
        //   ? this.field.invalidMessages.length
        //   : 0,

        "validate-on-blur": true,
        rules: this.field.componentVuetifyRules,

        // mark as read-only if read mode
        "read-only": this.isReadMode,
      };
    },
    fieldKey() {
      const modelId = this.field.model
        ? this.field.model.id
        : "dn" + Date.now();
      return modelId + "-" + this.field.id;
    },
    isReadMode() {
      if (!isNull(this.readMode)) return this.readMode;

      return (
        this.parentReadMode ||
        this.field.isReadonly ||
        this.field.isReadonlyToUser()
      );
    },
    componentName() {
      return this.isReadMode
        ? this.field.viewComponentName
        : this.field.formComponentName;
    },
    showDescTooltip() {
      return !this.$config.emphasizeFieldDescriptions || this.isReadMode;
    },
    showEmphasizedDescription() {
      return (
        !this.isReadMode &&
        this.$config.emphasizeFieldDescriptions &&
        this.field.description &&
        !this.inputOnly
      );
    },
  },
  watch: {},
};
