import {
  BooleanField,
  DateTimeField,
  ImageField,
  LongTextField,
  NumberField,
  RelationshipField,
  RelationshipFieldMany,
  StringField,
  UrlField,
} from "../plugins/Cruxtify/field-types";
import { CollectionLayout } from "../plugins/Cruxtify/layouts";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

import { CrudFilter } from "../plugins/Cruxtify/CrudFilter";
import {
  DateTimeProperty,
  ImageProperty,
  NumberProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import { IppyDippyCrudModel } from "./IppyDippyCrudModel";

const browseContentLayout = {
  type: CollectionLayout,
  opts: {
    id: "browse",
    componentName: "content-browser",
    enableSearch: true,
    noNew: true,
    noRefresh: true,
    filterFields: [
      "creator",
      "content_categories",
      "classroom_levels",
      "media_type",
    ],
    filters: [
      new CrudFilter({
        name: "private_only",
      }),
    ],
    enableDynamicFilters: false,

    defaultSort: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
  },
};

export class Content extends IppyDippyCrudModel {
  public static icon = "mdi-play-box-multiple-outline";
  protected static _typeLabel = "Content";
  protected static _typeLabelPlural = "Content";
  protected static asProperty = "content";
  public static api = {
    path: "contents",
  };
  protected static routeBase = "contents";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.SuperUser]: function (content) {
        return (content as Content).getProperty("is_editable").value
          ? UserPermission.Edit
          : UserPermission.Read;
      },
      [UserRole.LoggedIn]: UserPermission.New,
    },
    UserPermission.Read
  );

  protected userIdField = ["curator"];

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        displayRelative: true,
        label: "Entry Created At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        displayRelative: true,
        label: "Entry Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "deleted_at",
        displayRelative: true,
        label: "Entry Deleted At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "title",
        label: "Title",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "description",
        label: "Description",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "media_link",
        label: "Media Link",
        rules: ["required"],
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "duration",
        label: "Duration",
        description: "(in minutes)",
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "credit_hours",
        label: "Credit Hours",
        description:
          "When a user completes this content, how many credit hours should they receive?",
      },
    },
    {
      type: ImageProperty,
      opts: {
        name: "image_s3_id",
        label: "Image",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "creator",
        label: "Creator",
        relatedModel: "Creator",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "curator",
        label: "Curator",
        relatedModel: "User",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Read
        ),
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "media_type",
        label: "Media Type",
        relatedModel: "MediaType",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "classroom_levels",
        label: "Classroom Level(s)",
        relatedModel: "ClassroomLevel",
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "num_quiz_questions",
        label: "Number of Quiz Questions",
        description:
          "This is the number of quiz questions that will be chosen at random for a User to answer.",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "quiz_questions",
        label: "Quiz Questions",
        relatedModel: "QuizQuestion",
        foreignProperty: "content",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "content_categories",
        label: "Categories",
        relatedModel: "ContentCategory",
        foreignProperty: "contents",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "saved_by",
        label: "Saved by Users",
        relatedModel: "User",
        foreignProperty: "saved_contents",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "groups",
        label: "Private to Group(s)",
        relatedModel: "Group",
        foreignProperty: "contents",
      },
    },
    {
      type: "BooleanProperty",
      opts: {
        name: "saved_for_user",
        label: "Saved",
        userPermissions: UserPermission.Hidden,
        computed: true,
      },
    },
    {
      type: "BooleanProperty",
      opts: {
        name: "completed_for_user",
        label: "Completed",
        userPermissions: UserPermission.Hidden,
        computed: true,
      },
    },
    {
      type: "BooleanProperty",
      opts: {
        name: "is_editable",
        label: "Editable by Current User",
        userPermissions: UserPermission.Hidden,
        computed: true,
      },
    },
    {
      type: "BooleanProperty",
      opts: {
        name: "is_private",
        label: "Private to a Group",
        computed: true,
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Read,
          },
          UserPermission.Hidden
        ),
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    {
      type: DateTimeField,
      opts: { property: "created_at" },
    },
    { type: DateTimeField, opts: { property: "updated_at" } },
    { type: DateTimeField, opts: { property: "deleted_at" } },
    {
      type: StringField,
      opts: {
        property: "title",
        isPrimaryLabelField: true,
        cols: 12,
        // md: 9,
        // lg: 6,
      },
    },
    {
      type: LongTextField,
      opts: { property: "description" },
    },
    {
      type: UrlField,
      opts: { property: "media_link", cols: 12, lg: 9 },
    },
    {
      type: NumberField,
      opts: { property: "duration", sm: 6, md: 3 },
    },
    {
      type: NumberField,
      opts: { property: "credit_hours", sm: 6, md: 3 },
    },
    {
      type: ImageField,
      opts: { property: "image_s3_id", cols: 12, md: 6, lg: 3 },
    },
    {
      type: RelationshipField,
      opts: {
        property: "creator",
        md: 6,
        lg: 3,
        conditional: {
          property: "is_private",
          value: false,
        },
      },
    },
    {
      type: RelationshipField,
      opts: { property: "curator", md: 6, lg: 3 },
    },
    {
      type: RelationshipField,
      opts: { property: "media_type", sm: 6, md: 3 },
    },
    {
      type: RelationshipFieldMany,
      opts: { property: "classroom_levels", sm: 6, md: 3 },
    },
    {
      type: NumberField,
      opts: { property: "num_quiz_questions", sm: 6, md: 3 },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "quiz_questions",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutOpts: {
          enableSearch: false,
          noRefresh: true,
          cols: 12,
          navigateForNew: false,
          noNew: false,
        },
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "content_categories",
        sm: 6,
        md: 3,
      },
    },
    {
      type: BooleanField,
      opts: { property: "is_private" },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "groups",
      },
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      type: "TabbedModelLayout",
      opts: {
        id: "Crudform",
        tabs: [
          {
            id: "overviewTab",
            label: "Overview",
            content: {
              id: "Crudform",
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "0",
                    fields: [
                      "title",
                      "classroom_levels",
                      "content_categories",
                      "duration",
                      "credit_hours",
                      "description",
                    ],
                  },
                  {
                    id: "media",
                    fields: [
                      "creator",
                      "image_s3_id",
                      "media_type",
                      "media_link",
                    ],
                  },
                  {
                    id: "private",
                    fields: ["is_private", "groups"],
                  },
                  {
                    id: "meta",
                    fields: ["curator", "created_at", "updated_at", "id"],
                  },
                ],
              },
            },
          },
          {
            id: "quizTab",
            label: "Quiz",
            content: {
              id: "Crudform",
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "quiz",
                    fields: ["num_quiz_questions", "quiz_questions"],
                  },
                ],
              },
            },
          },
        ],
      },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      navigateForNew: true,
      columnFields: [
        "title",
        "creator",
        "content_categories",
        "classroom_levels",
        "media_type",
        "is_private",
      ],
      enableSearch: true,
      enableDynamicFilters: true,
      dynamicFilterOptions: {
        filterFields: [
          "title",
          "creator",
          "classroom_levels",
          "content_categories",
          "media_type",
          "duration",
          "credit_hours",
          "curator",
          "description",
          "quiz_questions",
          "media_link",
          "is_private",
          "groups",
        ],
      },
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },

    browseContentLayout,

    {
      ...browseContentLayout,
      opts: {
        ...browseContentLayout.opts,
        id: "saved-content",
        emphasizeNoneText: true,
        noneText:
          "You have not saved any content yet. Use the 'Save' button in the Browse Content section to save content to this collection for later.",
        filters: [
          ...browseContentLayout.opts.filters,

          new CrudFilter({
            name: "saved_only",
            isStatic: true,
            query: { saved_only: "1" },
          }),
        ],
      },
    },

    {
      id: "manage-group-content",
      useModal: true,
      navigateForNew: true,
      columnFields: [
        "title",
        "content_categories",
        "classroom_levels",
        "media_type",
      ],
      enableSearch: true,
      enableDynamicFilters: true,
      filters: [
        new CrudFilter({
          name: "private",
          query: { is_private: "1" },
          isStatic: true,
        }),
      ],
      dynamicFilterOptions: {
        filterFields: [
          "title",
          "classroom_levels",
          "content_categories",
          "media_type",
          "curator",
          "description",
          "quiz_questions",
          "media_link",
          "groups",
        ],
      },
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
  ];

  public getQuizLink() {
    return "/quiz/" + this.id;
  }

  public async buildQuiz() {
    return this.$nuxt.$api.POST("contents/" + this.id + "/build-quiz");
  }

  public async toggleSaved() {
    return this.$nuxt.$api.POST("contents/" + this.id + "/toggle-saved");
  }

  public async submitQuiz(questionData, answerData) {
    const quizData = {
      questions: questionData.map((question) => ({
        id: question.id,
        answers: question.answers.map((answer) => answer.id),
      })),
      answers: Object.keys(answerData).map((key) => {
        return {
          question_id: key,
          answer_id: answerData[key],
        };
      }),
    };
    return this.$nuxt.$api.POST(
      "contents/" + this.id + "/submit-quiz",
      quizData
    );
  }
}
