
import { CrudModel } from "../../CrudModel";

export default {
  data() {
    return {
      open: false,
      showJson: true,
      jsonTab: "",
      jsonTabs: {
        Serialized: "serializedPayload",
        Changes: "serializedChanges",
        "Plain Object": "toPlainObject",
      },
    };
  },
  props: {
    model: {
      type: CrudModel,
      required: true,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.open = false;
    },
    consoleLog() {
      console.log(this.model);
    },
    consoleLogRelationshipItem(item) {
      if (item.model) console.log(item.model);
      if (item.collection) console.log(item.collection);
    },
  },
  computed: {
    details() {
      const props = [
        "id",
        "isNew",
        "hasUnsavedChanges",
        "isValid",
        "isHydrated",
      ];

      return [
        ...props.map((prop) => ({ name: prop, value: this.model[prop] })),
        {
          name: "unsaved Props",
          value: this.model.unsavedProperties.map((prop) => prop.name),
        },
        {
          name: "Invalid Props",
          value: this.model.invalidProperties.map((prop) => prop.name),
        },

        // { name: "snapshots", value: this.model.snapshotIds },
      ];
    },
    relationships() {
      return this.model
        .findProperties()
        .filter(
          (prop) =>
            prop.isOfType("RelationshipProperty") ||
            prop.isOfType("RelationshipPropertyMany")
        )
        .map((prop) => ({
          name: prop.label,
          value: prop.get(),
          unsavedStatus: prop._hasUnsavedChanges
            ? "unsaved-prop"
            : prop.hasUnsavedChanges
            ? "unsaved-nested"
            : "clean",
          model: prop.modelInstance,
          collection: prop.collection,
        }));
    },
  },
};
