import { CrudModel } from "../CrudModel";
import { CrudProperty, ICrudProperty } from "../CrudProperty";

export interface INumberProperty extends ICrudProperty {
  decimals?: number;
  useLocaleString?: boolean;
}

export class NumberProperty extends CrudProperty {
  public decimals: number | null = null;
  public useLocaleString: boolean = true;

  constructor(opts: INumberProperty, model: CrudModel) {
    super(opts, model);

    if (typeof opts.decimals !== "undefined") this.decimals = opts.decimals;

    if (typeof opts.useLocaleString !== "undefined")
      this.useLocaleString = opts.useLocaleString;

    // defaults
    if (typeof opts.sortable === "undefined" && !this.isRemotelyComputed)
      this.sortable = true;
  }

  public coerceValue(val: unknown): any {
    if (val === null) return null;

    if (typeof val === "string") {
      val = val.replace(/[^0-9.-]/g, "");
      if ((val as string).length === 0) return null;
    }

    val = Number(val);
    if (isNaN(val as number)) val = 0;

    return super.coerceValue(val);
  }

  public get stringValue(): string {
    if (typeof this.value === "undefined") return "-";

    let stringValue = this.value;

    if (this.useLocaleString) {
      const decimalsOptions =
        this.decimals !== null
          ? {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          : undefined;

      stringValue = Number(stringValue).toLocaleString(
        undefined,
        decimalsOptions
      );
    }

    return stringValue;
  }

  public serializedValueGuarded(visited = new WeakSet<any>()): any {
    const val = this.get(true);
    return val !== null ? parseFloat((val + "").replace(/[^\d.-]/g, "")) : null;
  }
}
