
import { CrudField } from "../../CrudField";

export default {
  data() {
    return {
      open: false,
      showJson: false,
      jsonTab: "",
      jsonTabs: {
        Serialized: "serializedPayload",
        Changes: "seralizedChangesPayload",
      },
    };
  },
  props: {
    field: {
      type: CrudField,
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.open = false;
    },
    consoleLog(val) {
      if (typeof val === "function") val = val();
      console.log(val);
    },
    consoleLogRelationshipItem(item) {
      if (item.field) console.log(item.field);
      if (item.collection) console.log(item.collection);
    },
  },
  computed: {
    details() {
      const fieldProps = [
        "value",
        "isValid",
        "_opts",
        "isOrphan",
        "_userPermissions",
      ];
      const propProps = [
        "_value",
        "isEmpty",
        "isTouched",
        "_hasUnsavedChanges",
        "hasUnsavedChanges",
        "serializedChangesValue",
        "serializedPayload",
        "unsavedValue",
        "isRemotelyComputed",
        "_isHydrated",
        "isHydrated",
        "_opts",
        "_userPermissions",
      ];

      const snapshots = [];
      // this.field.property.snapshots.map(
      //   (snapshot, index) =>
      //     snapshot.id +
      //     ": " +
      //     (snapshot.data && snapshot.data.id ? snapshot.data.id : snapshot.data)
      // );

      return [
        ...fieldProps.map((prop) => ({
          name: "field." + prop,
          value: this.field[prop],
        })),
        ...propProps.map((prop) => ({
          name: "prop." + prop,
          value: this.property[prop],
        })),
        { name: "snapshots", value: snapshots },
      ].filter((field) => {
        try {
          field.value.toString();
          return true;
        } catch (e) {
          return false;
        }
      });
    },
    property() {
      return this.field.property;
    },
    plainObject() {
      return 0 &&
        this.property.typedValue &&
        this.property.typedValue.toPlainObject
        ? this.property.typedValue.toPlainObject()
        : this.field.get();
    },
    logs() {
      const props = ["field", "property", "plainObject"];

      const logs = [
        ...props.map((prop) => ({ name: prop, value: this[prop] })),
      ].filter((field) => {
        try {
          field.value.toString();
          return true;
        } catch (e) {
          return false;
        }
      });
      return logs;
    },
  },
};
