import { ChoiceField, IChoiceField } from "./ChoiceField";

export interface ISelectField extends IChoiceField {}
export class SelectField extends ChoiceField {
  public static fieldName = "SelectField";
  public static formComponentName: string = "form-select-field";

  public get requiresDetachedLabel() {
    return super.requiresDetachedLabel || !!this.nullLabel;
  }
}
