import { CrudField, ICrudField } from "../CrudField";
import { CrudModel } from "../CrudModel";
import { ArrayStringProperty } from "../property-types/ArrayStringProperty";

export interface IChoiceField extends ICrudField {
  options?: string[] | object;
  multiple?: boolean;
  nullLabel?: string;
}
export class ChoiceField extends CrudField {
  public static fieldName = "ChoiceField";
  public static viewComponentName: string = "view-choice-field";
  protected static readonly defaultPropertyType = ArrayStringProperty;

  public multiple = false;
  public nullLabel?: string;

  protected _options: string[] | object = [];
  public get options(): object {
    let choices = this._options;

    if (Array.isArray(this._options)) {
      choices = (this._options as string[]).reduce(
        (keyVal, val) =>
          Object.assign(keyVal, {
            [val]: val,
          }),
        {}
      );
    }

    return choices;
  }

  public get items() {
    const options = this.options;

    return Object.keys(options).map((value) => {
      return {
        text: options[value],
        value,
      };
    });
  }

  // public get value() {
  //   if (!super.value && super.value !== 0) return;

  //   return {
  //     value: "" + super.value,
  //     text: this.options[super.value]
  //   };
  // }

  public get stringValue() {
    if (this.isEmpty) return;

    if (this.multiple) {
      return super.value.map((val) => this.options[val]).join(", ");
    }

    return this.options[super.value];
  }

  public get objectValue() {
    if (!super.value && super.value !== 0) return;

    return {
      value: "" + super.value,
      text: this.options[super.value],
    };
  }

  public set objectValue(itemValue) {
    this.value = itemValue?.value;
  }

  constructor(opts: IChoiceField, model: CrudModel) {
    super(opts, model);

    if (typeof opts.options !== "undefined") this._options = opts.options;

    // TODO maybe infer this from the property value type? if array, this is true
    if (typeof opts.multiple !== "undefined") this.multiple = opts.multiple;

    if (typeof opts.nullLabel !== "undefined") this.nullLabel = opts.nullLabel;

    // nextTick
    setTimeout(() => {
      if (
        (Array.isArray(this.options) && this.options.length === 0) ||
        Object.keys(this.options).length === 0
      )
        console.warn("Choice field is missing choices: ", this.label, this);
    }, 0);
  }
}
