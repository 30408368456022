import { CrudField, CrudFieldQuery } from "./CrudField";
import { CrudModel } from "./CrudModel";

export interface ICrudFieldSetDefinition {
  id: string;
  width?: number;
  cols?: number | string;
  xl?: number | string;
  lg?: number | string;
  md?: number | string;
  sm?: number | string;
  title?: string;
  fields?: CrudFieldQuery[];
  readonly?: boolean;
  isEnabled?: boolean;
  disableFields?: CrudFieldQuery[];
}
export interface ICrudFieldSet extends ICrudFieldSetDefinition {
  model: CrudModel;
}
export class CrudFieldSet {
  public id: string;
  public model: CrudModel;
  public width: number = 50;
  public title = "";
  public xl: number | string | undefined;
  public lg: number | string | undefined;
  public md: number | string | undefined;
  public sm: number | string | undefined;
  public cols: number | string = 12;
  public readonly = false;
  private _fieldDefs: CrudFieldQuery[] = [];
  private _fields: CrudField[] = [];
  public disableFields: CrudFieldQuery[] = [];
  public get fields() {
    if (this._fields.length === 0) {
      this._fields = this.model.getFields(this._fieldDefs);
    }

    return this._fields.filter(
      (field) => !this.disableFields.some((df) => df == field.id)
    );
  }

  public get fieldDefs() {
    return this._fieldDefs;
  }

  // "enabled" generally means visible and not ignored for things like validation
  protected _isEnabled = true;
  public get isEnabled() {
    if (!this._isEnabled) return false;

    const fieldsEnabled = this.fields.filter((field) => field.isEnabled);

    return fieldsEnabled.length > 0;
  }
  public set isEnabled(value) {
    this._isEnabled = value;
  }

  constructor(opts: ICrudFieldSet) {
    this.id = opts.id;
    this.model = opts.model;
    if (opts.width) this.width = opts.width;

    if (opts.xl) this.xl = opts.xl;
    if (opts.lg) this.lg = opts.lg;
    if (opts.md) this.md = opts.md;
    if (opts.sm) this.sm = opts.sm;
    if (opts.cols) this.cols = opts.cols;

    if (opts.title) this.title = opts.title;
    if (opts.fields) this._fieldDefs = opts.fields;
    if (opts.disableFields) this.disableFields = opts.disableFields;
    if (typeof opts.isEnabled !== "undefined") this.isEnabled = opts.isEnabled;
    if (typeof opts.readonly !== "undefined") this.readonly = opts.readonly;
  }

  public get colAttributes() {
    const attributes: any = {};

    const colProps = ["xl", "lg", "md", "sm", "cols"];

    colProps
      .filter((prop) => typeof this[prop] !== "undefined")
      .forEach((prop) => (attributes[prop] = this[prop]));

    return attributes;
  }
}
