
import { CrudModel } from "../CrudModel";

export default {
  props: {
    model: {
      type: CrudModel,
      required: true,
    },
    saveRequestOptions: {
      type: Object,
      default: () => ({}),
    },
    hideDelete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      itemLabel: "",
      saveButtonLabel: "Save",
      dialogDelete: false,

      errorDialog: false,
      errorMessage: "",
      errorList: [],
    };
  },
  methods: {
    save() {
      this.errorMessage = "";
      this.errorList = [];

      this.model
        .save(this.saveRequestOptions)
        .then((res) => {
          this.saveButtonLabel = "Saved";
          this.$emit("saved", res);
        })
        .catch((error) => {
          if (
            !error.payload ||
            error.is_application_error ||
            error.message == "Server Error"
          ) {
            this.errorMessage =
              "Something went wrong on our end. Please try again later. If the problem persists, contact support.";
            return;
          }

          if (error.is_timeout_error) {
            this.errorMessage =
              "The request took too long to process. Your data may not have been saved. Please check and try again.";
            return;
          }

          this.errorMessage = error.message;

          if (error.payload.validation_errors) {
            this.errorMessage = "There is a problem with the data you entered:";
            this.errorList = Object.keys(error.payload.validation_errors).map(
              (key) => {
                return (
                  this.model.getProperty(key).label +
                  " - " +
                  error.payload.validation_errors[key].join(", ")
                );
              }
            );
          }

          this.errorDialog = true;
        });
    },

    deleteItemConfirm() {
      this.model.delete().then((res) => {
        this.closeDelete();
        this.$emit("deleted", res);
      });
    },

    closeDelete() {
      this.dialogDelete = false;
    },
  },
  computed: {
    showRevert() {
      return this.$nuxt.$config.showModelRevert;
    },
  },
  watch: {
    model: {
      handler() {
        this.saveButtonLabel = "Save";
        this.itemLabel = this.model.label;
      },
      immediate: true,
    },
    "model.hasUnsavedChanges": {
      handler() {
        this.saveButtonLabel = "Save";
      },
    },
  },
};
