import { CrudField, CrudFieldQuery } from "../CrudField";
import { CrudModel } from "../CrudModel";
import {
  CompareConditional,
  HasCompareConditional,
  ICompareConditional,
} from "./CompareConditional";

export interface IFieldConditional extends ICompareConditional {
  field?: CrudFieldQuery;
}

export interface HasFieldConditional extends HasCompareConditional {
  model: CrudModel;
  field?: CrudField;
}

export class FieldConditional extends CompareConditional {
  protected field: CrudField | undefined;

  constructor(opts: IFieldConditional, owner: HasFieldConditional) {
    super(opts, owner);

    let fieldQuery;

    if (opts.field) fieldQuery = opts.field;
    else if (owner instanceof CrudField) fieldQuery = owner;
    else {
      console.log({ fieldQuery, owner });
      throw new Error(
        "FieldConditional must have a field defined on either itself or its owner"
      );
    }

    owner.model
      .awaitField(fieldQuery)
      .then((field) => {
        this.field = field;
      })
      .catch((err) => {
        console.error(err, opts, this);
        throw new Error("Unable to resolve field: " + fieldQuery);
      });
  }

  public get result(): boolean {
    const superResult = super.result;

    if (!this.field) return false;

    return superResult;
  }

  protected get compare() {
    return this.field ? this.field.value : undefined;
  }
}
