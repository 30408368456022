import { CrudProperty } from "../CrudProperty";

export class BooleanProperty extends CrudProperty {
  protected _value: boolean | null = null;

  public sortable: boolean = true;

  public get stringValue(): string {
    return this.isEmpty ? "" : this.value ? "✔" : "✘";
  }

  public coerceValue(value: unknown): any {
    if (value === null) return null;

    let asBool;
    if (typeof value === "string") {
      asBool = ["yes", "true", "1"].includes(value.toLowerCase());
    } else if (typeof value === "number") {
      asBool = value >= 1;
    } else {
      asBool = !!value;
    }

    return super.coerceValue(asBool);
  }

  public serializedValueGuarded(visited = new WeakSet<any>()): any {
    const val = this.get(true);
    if (val === null) return null;
    return val ? 1 : 0;
  }
}
