
export default {
  data() {
    return {
      values: [],
      listLimit: 5,
    };
  },
  props: {
    url: null,
    linkClass: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    allClasses() {
      const requiredClasses = {
        "relation-chip-link": true,
        disabled: this.disabled,
      };

      return {
        ...requiredClasses,
        ...this.linkClass,
      };
    },
    urlIsExternal() {
      return this.url.startsWith("http");
    },
  },
};
