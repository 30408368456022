import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

import { IppyDippyCrudModel } from "./IppyDippyCrudModel";

export class Creator extends IppyDippyCrudModel {
  public static icon = "mdi-creation";
  protected static _typeLabel = "Creator";
  protected static _typeLabelPlural = "Creators";
  protected static asProperty = "creator";
  public static api = {
    path: "creators",
  };
  protected static routeBase = "creators";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.New,
    },
    UserPermission.Read
  );

  protected userIdField = [];

  protected static propertyDefinitions = [
    {
      type: "NumberProperty",
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: "DateTimeProperty",
      opts: {
        name: "created_at",
        displayRelative: true,
        label: "Entry Created At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: "DateTimeProperty",
      opts: {
        name: "updated_at",
        displayRelative: true,
        label: "Entry Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: "DateTimeProperty",
      opts: {
        name: "deleted_at",
        displayRelative: true,
        label: "Entry Deleted At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: "StringProperty",
      opts: {
        name: "name",
        label: "Name",
        rules: ["required"],
      },
    },
    {
      type: "StringProperty",
      opts: {
        name: "url",
        label: "URL",
      },
    },
    {
      type: "RelationshipPropertyMany",
      opts: {
        name: "contents",
        label: "Content",
        relatedModel: "Content",
        foreignProperty: "creator",
      },
    },
    {
      type: "RelationshipProperty",
      opts: {
        name: "curator",
        label: "Curator",
        relatedModel: "User",
        foreignProperty: "creators",
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: "NumberField", opts: { property: "id" } },
    {
      type: "DateTimeField",
      opts: { property: "created_at" },
    },
    { type: "DateTimeField", opts: { property: "updated_at" } },
    { type: "DateTimeField", opts: { property: "deleted_at" } },
    {
      type: "StringField",
      opts: { property: "name", isPrimaryLabelField: true, cols: 12 },
    },
    { type: "StringField", opts: { property: "url" } },
    {
      type: "RelationshipFieldMany",
      opts: {
        property: "contents",
        viewComponentName: "view-relationship-field-many-labels",
        formComponentName: "form-relationship-field-many-table",
      },
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
      fieldSets: [
        {
          id: "0",
          fields: ["name", "url", "contents"],
        },
        {
          id: "1",
          fields: ["created_at", "updated_at"],
        },
      ],
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      columnFields: ["name"],
      enableSearch: true,
      useModal: true,
      enableDynamicFilters: true,
      dynamicFilterOptions: {
        filterFields: ["name", "content"],
      },
      defaultSort: [
        {
          field: "name",
          order: "asc",
        },
      ],
    },
  ];
}
