import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import {
  DateTimeField,
  FileField,
  LongTextField,
  NumberField,
  RelationshipField,
  StringField,
} from "../plugins/Cruxtify/field-types";

import {
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import { IppyDippyCrudModel } from "./IppyDippyCrudModel";

export class Attachment extends IppyDippyCrudModel {
  protected static _typeLabel = "Attachment";
  protected static _typeLabelPlural = "Attachments";
  protected static asProperty = "attachment";
  public static api = {
    path: "attachments",
  };
  protected static routeBase = "attachments";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.New,
    },
    UserPermission.Read
  );

  protected userIdField = ["user"];

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        displayRelative: true,
        label: "Entry Created At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        displayRelative: true,
        label: "Entry Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "deleted_at",
        displayRelative: true,
        label: "Entry Deleted At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "label",
        label: "Label",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "description",
        label: "Description",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "filename",
        label: "File Name",
        computed: true,
      },
    },
    {
      type: "FileProperty",
      opts: {
        name: "s3_id",
        label: "File",
        rules: ["required"],
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "user",
        label: "Uploader",
        relatedModel: "User",
        computed: true,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "credit",
        label: "Credit",
        relatedModel: "Credit",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "external_activity",
        label: "External Activity",
        relatedModel: "ExternalActivity",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "license",
        label: "License",
        relatedModel: "License",
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    {
      type: DateTimeField,
      opts: { property: "created_at" },
    },
    { type: DateTimeField, opts: { property: "updated_at" } },
    { type: DateTimeField, opts: { property: "deleted_at" } },
    {
      type: StringField,
      opts: { property: "label", isPrimaryLabelField: true, cols: 12 },
    },
    {
      type: StringField,
      opts: { property: "filename", cols: 12 },
    },
    {
      type: LongTextField,
      opts: { property: "description", cols: 12 },
    },
    {
      type: FileField,
      opts: { property: "s3_id", cols: 12 },
    },
    {
      type: RelationshipField,
      opts: { property: "credit", md: 6 },
    },
    {
      type: RelationshipField,
      opts: {
        property: "user",
        md: 6,
        computed: true,
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: RelationshipField,
      opts: { property: "external_activity", md: 6 },
    },
    {
      type: RelationshipField,
      opts: { property: "license", md: 6 },
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
      fieldSets: [
        {
          id: "0",
          md: 8,
          xl: 4,
          fields: ["label", "description"],
        },
        {
          id: "media",
          md: 4,
          fields: ["s3_id", "filename"],
        },
        {
          id: "meta",
          md: 12,
          xl: 4,
          fields: ["user", "created_at", "updated_at", "id"],
        },
      ],
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      columnFields: ["label", "s3_id", "description"],
      enableSearch: true,
      enableDynamicFilters: true,
      dynamicFilterOptions: {
        filterFields: ["label", "user", "description"],
      },
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
  ];
}
