
import { DataTableLayout } from "../../../../layouts/collection/DataTableLayout";

export default {
  data() {
    return {
      isExpanded: false,
      dragging: false,
      bubbleTableRowEvents: {
        "edit-item": (item) => this.$emit("edit-item", item),
        "delete-item": (item) => this.$emit("delete-item", item),
        "restore-item": (item) => this.$emit("restore-item", item),
      },
    };
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    layout: {
      type: DataTableLayout,
      required: true,
    },
    showModelActions: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    updateRow(update) {
      Object.keys(update).forEach((key) => {
        this.item[key] = update[key];
      });
    },

    setRowSaving() {
      this.updateRow({ apiStatus: "saving" });
    },

    setRowSaved() {
      this.updateRow({ apiStatus: "saved" });
    },

    inlineEditChanged() {
      if (!this.showModelActions) return;

      this.setRowSaving();

      const item = rowData.instance;

      item.save().then(() => this.setRowSaved(rowData));
    },

    rowChildSaved(rowData) {
      this.expanded = [];
      this.setRowSaved(rowData);
    },
  },
  computed: {
    tableRowProps() {},
  },
  mounted() {
    if (!this.layout.collection.newInstances) return;

    const newestInstance =
      this.layout.collection.newInstances[
        this.layout.collection.newInstances.length - 1
      ];
    if (newestInstance && newestInstance.id === this.item.instance.id) {
      this.isExpanded = true;
    }
  },
};
