import _ from "lodash";
import { CrudFieldQuery } from "../CrudField";
import {
  CrudLayout,
  CrudLayoutDefinition,
  CrudLayoutOpts,
} from "../CrudLayout";
import { CrudModel, CrudModelType } from "../CrudModel";
import { ApiGetOneOptions } from "../api";

export type NavigateToDef =
  | string // Path
  | false // Do not navigate
  | Function // method that returns a NavigateToDef (or may exectue arbitrary code)
  | {
      path: string;
      params?: Record<string, any>;
    };

export interface ModelLayoutDefinition extends CrudLayoutDefinition {
  headerComponents?: string[];
  footerComponents?: string[];
  disableDefaultModelActions?: boolean;
  navigateAfterSave?: NavigateToDef | false;
  navigateAfterDelete?: NavigateToDef;
  modelRequestOptions?: ApiGetOneOptions;
  disableFields?: CrudFieldQuery[];
}

interface ModelLayoutOptsFromModel
  extends CrudLayoutOpts,
    ModelLayoutDefinition {
  model: CrudModel;
}
interface ModelLayoutOptsFromModelType
  extends CrudLayoutOpts,
    ModelLayoutDefinition {
  modelType: CrudModelType;
}

export type ModelLayoutOpts =
  | ModelLayoutOptsFromModel
  | ModelLayoutOptsFromModelType;
export class ModelLayout extends CrudLayout {
  public model!: CrudModel;
  public modelType!: CrudModelType;
  public headerComponents: string[] = [];
  public footerComponents: string[] = [];
  public disableDefaultModelActions: boolean = false;
  public disableFields: CrudFieldQuery[] = [];

  protected _modelRequestOptions: ApiGetOneOptions = {};
  public get modelRequestOptions(): ApiGetOneOptions {
    return this._modelRequestOptions;
  }

  // Navigate after save/delete
  protected _navigateAfterSave: NavigateToDef | null = null;
  public navigateAfterSave(fallback?: NavigateToDef): void {
    this._handleNavigateAfter("_navigateAfterSave", fallback);
  }

  protected _navigateAfterDelete: NavigateToDef | null = null;
  public navigateAfterDelete(fallback?: NavigateToDef): void {
    this._handleNavigateAfter("_navigateAfterDelete", fallback);
  }

  private _handleNavigateAfter(property, fallback?: NavigateToDef) {
    return this.executeNavigateToDef(this[property], fallback);
  }

  protected executeNavigateToDef(
    def: NavigateToDef,
    fallback?: NavigateToDef
  ): void {
    if (typeof def === "function") def = def.call(this.model);

    if (def === false) return;

    if (def === null || typeof def === "undefined") {
      // go back by default
      if (typeof fallback === "undefined") return this.$nuxt.app.router.go(-1);

      return this.executeNavigateToDef(fallback);
    }

    const pushRoute = typeof def === "string" ? { path: def } : def;
    this.$nuxt.app.router.push(pushRoute);
  }

  constructor(opts: ModelLayoutOpts) {
    super(opts);

    if (typeof opts.model !== "undefined") {
      this.setModel(opts.model);
    } else {
      this.modelType = opts.modelType;
      this.model = new this.modelType();
    }

    if (typeof opts.headerComponents !== "undefined")
      this.headerComponents = opts.headerComponents;

    if (typeof opts.footerComponents !== "undefined")
      this.footerComponents = opts.footerComponents;

    if (typeof opts.disableDefaultModelActions !== "undefined")
      this.disableDefaultModelActions = opts.disableDefaultModelActions;

    if (
      this.$nuxt.$config.debug &&
      !this.headerComponents.includes("model-debugger")
    )
      this.headerComponents.push("model-debugger");

    if (typeof opts.navigateAfterSave !== "undefined")
      this._navigateAfterSave = opts.navigateAfterSave;

    if (typeof opts.navigateAfterDelete !== "undefined")
      this._navigateAfterDelete = opts.navigateAfterDelete;
    else {
      const modelRouteList = this.modelType.getRouteList();
      // Default navigate to list view after delete if set on model
      if (modelRouteList !== false)
        this._navigateAfterDelete = { path: modelRouteList as string };
    }

    if (typeof opts.modelRequestOptions !== "undefined")
      this._modelRequestOptions = opts.modelRequestOptions;

    if (typeof opts.disableFields !== "undefined")
      this.disableFields = opts.disableFields;
  }

  public loading = true;
  public async getModel(id: number): Promise<CrudModel> {
    this.loading = true;
    return this.modelType
      .getOne(id, this.modelRequestOptions)
      .then((model) => (this.model = model));
  }

  public setModel(model: CrudModel) {
    this.model = model;
    this.modelType = this.model.getModelType();
  }

  public newModel(modelOpts = {}) {
    this.loading = false;
    this.model = new this.modelType(modelOpts);
    return this.model;
  }

  public newForModel(
    model: CrudModel,
    additionalOpts: Partial<ModelLayoutOpts> = {}
  ) {
    const opts = _.merge(this._opts, additionalOpts);
    opts.model = model;

    return new (this.constructor as any)(opts);
  }
}
