
import * as crudModels from "~/models";

const resetQuery = (vm) => {
  vm.layout.collection.clearFiltersExcept([...vm.queryStringFilters]);
  vm.layout.collection.resetQueryOptionsExcept(
    vm.currentOptionKeys.map((queryName) => queryName.substr(1))
  );
  vm.layout.collection.applyStagedFilters();
};

export default {
  data() {
    const layout = crudModels[this.modelName].getCollectionLayout(
      this.layoutId
    );

    return {
      listTitle: crudModels[this.modelName].listTitle,
      icon: crudModels[this.modelName].icon,
      layout,
    };
  },
  props: {
    modelName: {
      type: String,
      required: true,
    },
    layoutId: {
      type: String,
    },
  },
  computed: {
    currentOptionKeys() {
      return Object.keys(this.$route.query).filter(
        (queryName) => queryName.indexOf("_") === 0
      );
    },
    queryStringFilters() {
      return Object.keys(this.$route.query)
        .filter((queryName) => queryName.indexOf("_") !== 0)
        .filter((queryName) => this.layout.collection.getFilter(queryName));
    },
  },
  watch: {
    "layout.collection.filterQueryValues": {
      handler() {
        const setFilters = _.pickBy(
          this.layout.collection.filterQueryValues,
          (value) => value || value === 0
        );

        const queryFiltersString = new URLSearchParams(setFilters).toString();
        const existingQueryOptionsString = new URLSearchParams(
          _.pick(this.$route.query, this.currentOptionKeys)
        ).toString();

        const allQueryStrings = [
          queryFiltersString,
          existingQueryOptionsString,
        ].filter((s) => s.length > 0);

        const newStateUrl =
          this.$route.path +
          (allQueryStrings.length > 0 ? "?" + allQueryStrings.join("&") : "");

        const curUrl = new URL(document.location.href);
        const curStateUrl = curUrl.pathname + curUrl.search;

        if (curStateUrl != newStateUrl) {
          history.pushState({}, "", newStateUrl);
        }
      },
    },
    "$route.query": {
      handler() {
        // filters
        this.queryStringFilters.forEach((key) => {
          this.layout.collection.setFilter(key, this.$route.query[key]);
        });
        this.layout.collection.applyStagedFilters();

        // query options
        this.currentOptionKeys
          .map((queryName) => queryName.substr(1))
          .filter((queryOption) =>
            this.layout.collection.remoteQueryOptions.hasOwnProperty(
              queryOption
            )
          )
          .forEach((queryOption) => {
            const key = "_" + queryOption;
            const optionValue =
              queryOption.indexOf("sort") === 0
                ? this.$route.query[key].split(",")
                : this.$route.query[key];

            this.layout.collection.setQueryOption(queryOption, optionValue);
          });
      },
      immediate: true,
    },
  },
  beforeRouteUpdate(to, from, next) {
    setTimeout(() => resetQuery(this), 100);
    next();
  },
  beforeRouteEnter(to, from, next) {
    next(resetQuery);
  },
};
