import { CrudField, CrudFieldQuery, ICrudFieldDefinition } from "./CrudField";

import {
  HasUserPermissions,
  PermissableEntity,
  UserPermission,
  UserPermissions,
  UserRole,
} from "./UserPermission";

import * as pluralize from "pluralize";

import {
  CrudLayout,
  CrudLayoutDefinition,
  TypedCrudLayoutDefinition,
} from "./CrudLayout";
import {
  CrudProperty,
  CrudPropertyGet,
  CrudPropertyQuery,
  HasProperties,
  ICrudPropertyDefinition,
  ICrudPropertyGet,
} from "./CrudProperty";
import { ApiGetOneOptions } from "./api";
import { CollectionLayout } from "./layouts";

export interface IToPlainObjectOpts extends ICrudPropertyGet {
  onlyProperties?: CrudPropertyQuery[];
}
export type CrudModelType = typeof CrudModel;
export type ICrudModelType = CrudModelType | string;

export type CrudFieldsByRelativeId = Record<string, CrudField>;

export interface HasFields {
  findField(
    fieldQuery: CrudFieldQuery,
    optionalResult?: boolean
  ): CrudField | undefined;
  findFields(
    fieldQuery?: CrudFieldQuery[] | CrudFieldQuery
  ): CrudField[] | undefined;
}

interface ModelApiConfig {
  path: string;
  fullCache?: boolean;
}

type HydrateModelOpts = ApiGetOneOptions;

export class CrudModel
  implements HasFields, HasProperties, HasUserPermissions, PermissableEntity
{
  protected static _typeLabel = "Base Entity";
  protected static _typeLabelPlural?: string;
  protected static _listTitle?: string;
  public static icon: string = "";

  // default asProperty for this model when used as a field
  protected static asProperty = "";

  public static get typeLabel() {
    return this._typeLabel;
  }
  public static get typeSlug() {
    return this._typeLabel.replace(/\s+/g, "-").toLowerCase();
  }
  public static get typeLabelPlural() {
    if (this._typeLabelPlural) return this._typeLabelPlural;
    return pluralize(this.typeLabel);
  }
  public get typeLabel() {
    return this._classRef.typeLabel;
  }
  public get typeSlug() {
    return this._classRef.typeSlug;
  }
  public get typeLabelPlural() {
    return this._classRef.typeLabelPlural;
  }

  public static get listTitle() {
    return this._listTitle || this.typeLabelPlural;
  }
  public get listTitle() {
    return this._classRef.listTitle;
  }
  public get icon() {
    return this._classRef.icon;
  }

  protected static get refInstance(): CrudModel {
    return new this();
  }

  private static _crudModelTypes: Record<string, ICrudModelType> = {};
  public static getModelType(type: ICrudModelType): CrudModelType {
    if (typeof type !== "string") return type;

    if (!this._crudModelTypes[type]) {
      console.error(this._crudModelTypes);
      throw new Error("CrudModelType not registered: " + type);
    }

    return this._crudModelTypes[type] as CrudModelType;
  }
  public static registerModelType(name: string, type: ICrudModelType) {
    this._crudModelTypes[name] = type;
  }
  public static registerModelTypes(
    registrationMap: Record<string, ICrudModelType> = {}
  ) {
    Object.keys(registrationMap).forEach((name: string) =>
      this.registerModelType(name, registrationMap[name] as CrudModelType)
    );
  }
  public getModelType() {
    return this._classRef;
  }

  protected static $nuxt;
  public static setNuxtContext(context) {
    this.$nuxt = context;
  }
  public get $nuxt() {
    return this._classRef.$nuxt;
  }

  protected static propertyDefinitions: ICrudPropertyDefinition[] = [];
  protected _properties: CrudProperty[] = [];
  protected get properties() {
    return this._properties;
  }

  protected static fieldDefinitions: ICrudFieldDefinition[] = [];
  protected _fields: CrudField[] = [];
  protected get fields() {
    if (this._fields.length === 0) {
      this._fields = CrudField.newInstances(
        this._classRef.fieldDefinitions,
        this
      );
    }
    return this._fields;
  }

  public static api: ModelApiConfig = {
    path: "",
    fullCache: false,
  };

  public get api() {
    return this._classRef.api;
  }

  protected static routeBase: string = "";

  protected userIdField: CrudFieldQuery[] = ["user"];
  public getUserId() {
    if (!this.userIdField || this.userIdField.length === 0) return null;

    let field = this.userIdField
      .map((field) => {
        return this.findField(field);
      })
      .filter((field) => field)[0];

    if (!field) {
      console.warn("userIdField not found on model: ", this.userIdField, this);
      return null;
    }

    const fieldVal = field.value;
    if (!fieldVal || fieldVal.length === 0) return null;
    else return fieldVal;
  }

  protected static listFields: string[] = [];
  protected static collectionLayoutDefinitions:
    | TypedCrudLayoutDefinition[]
    | CrudLayoutDefinition[] = [
    {
      type: "DataTableLayout",
      opts: {
        id: "defaultCollectionLayout",
      },
    },
  ];
  protected static _collectionLayouts: CrudLayout[] = [];
  protected static modelLayoutDefinitions:
    | TypedCrudLayoutDefinition[]
    | CrudLayoutDefinition[] = [
    {
      type: "FieldSetGridLayout",
      opts: {
        id: "defaultModelLayout",
      },
    },
  ];

  protected _modelLayouts: CrudLayout[] = [];
  protected get modelLayouts() {
    if (
      this._modelLayouts.length == 0 &&
      this._classRef.modelLayoutDefinitions.length > 0
    ) {
      // support old style for now
      this._classRef.modelLayoutDefinitions =
        this._classRef.modelLayoutDefinitions.map((layoutDef) => {
          if (
            !layoutDef.hasOwnProperty("type") &&
            !layoutDef.hasOwnProperty("opts")
          ) {
            // deprecated
            return { type: "FieldSetGridLayout", opts: layoutDef };
          }

          return layoutDef;
        });

      this._modelLayouts = CrudLayout.newInstances(
        this._classRef.modelLayoutDefinitions as TypedCrudLayoutDefinition[],
        { model: this }
      );
    }
    return this._modelLayouts;
  }

  public getModelLayout(id?: string) {
    if (!id) id = this.getDefaultModelLayoutId();

    const layout = this.modelLayouts.find((layout) => layout.id == id);
    if (!layout)
      throw new Error(
        `ModelLayout "${id}" not found on model "${this.typeLabel}"`
      );

    return layout;
  }

  protected getDefaultModelLayoutId() {
    return this.modelLayouts[0].id;
  }

  public static getModelLayout(id?: string) {
    if (!id) id = this.refInstance.getDefaultModelLayoutId();

    // support old style for now
    const layout = this.modelLayoutDefinitions
      .map((layoutDef) => {
        if (
          !layoutDef.hasOwnProperty("type") &&
          !layoutDef.hasOwnProperty("opts")
        ) {
          // deprecated
          return { type: "FieldSetGridLayout", opts: layoutDef };
        }

        return layoutDef;
      })
      .find((layoutDef) => layoutDef.opts.id == id);

    if (!layout)
      throw new Error(
        `ModelLayout "${id}" not found on model "${this.typeLabel}"`
      );

    return CrudLayout.newInstance(layout, { modelType: this });
  }

  public static getCollectionLayout(id?: string) {
    // initialize if necessary
    if (this._collectionLayouts.length === 0) {
      // support old style for now
      this.collectionLayoutDefinitions = this.collectionLayoutDefinitions.map(
        (layoutDef) => {
          if (
            !layoutDef.hasOwnProperty("type") &&
            !layoutDef.hasOwnProperty("opts")
          ) {
            // deprecated
            return { type: "DataTableLayout", opts: layoutDef };
          }

          return layoutDef;
        }
      );

      this._collectionLayouts = CollectionLayout.newInstances(
        this.collectionLayoutDefinitions as TypedCrudLayoutDefinition[],
        { modelType: this }
      );
    }

    if (!id) id = this.getDefaultCollectionLayoutId();

    const layout = this._collectionLayouts.find((layout) => layout.id == id);
    if (!layout)
      throw new Error(
        `CollectionLayout "${id}" not found on model "${this.typeLabel}"`
      );

    return layout;
  }

  protected static getDefaultCollectionLayoutId() {
    return this._collectionLayouts[0].id;
  }

  protected static userPermissions = new UserPermissions({
    [UserRole.SuperAdmin]: UserPermission.Edit,
    [UserRole.SuperUser]: UserPermission.Edit,
    [UserRole.EntityOwner]: UserPermission.Edit,
    [UserRole.LoggedIn]: UserPermission.Edit,
    [UserRole.Guest]: UserPermission.New,
  });

  public static isVisibleToUser() {
    return this.refInstance.isVisibleToUser();
  }
  public static isReadonlyToUser() {
    return this.refInstance.isReadonlyToUser();
  }
  public static userCanCreateNew() {
    return this.refInstance.userCanCreateNew();
  }
  public isVisibleToUser() {
    if (!this._classRef.userPermissions) return true;
    return this._classRef.userPermissions.isVisibleToUser(this);
  }
  public isReadonlyToUser() {
    if (!this._classRef.userPermissions) return false;
    return this._classRef.userPermissions.isReadonlyToUser(this);
  }
  public userCanCreateNew() {
    if (!this._classRef.userPermissions) return false;
    return this._classRef.userPermissions.userCanCreateNew(this);
  }
  public static getPermissions() {
    return this.userPermissions;
  }
  public getPermissions() {
    return this._classRef.userPermissions;
  }

  protected get _classRef(): CrudModelType {
    return Object.getPrototypeOf(this).constructor;
  }
  public get modelType(): CrudModelType {
    return this._classRef;
  }

  protected _data: object | CrudModel;
  constructor(data: object | CrudModel = {}) {
    this._properties = CrudProperty.newInstances(
      this._classRef.propertyDefinitions,
      this
    );

    this._data = data;
    this.reset();
  }

  public set(val: object | CrudModel = {}, skipMarkingAsUnsaved = false) {
    let valJson = val;
    if (typeof (val as CrudModel).toPlainObject === "function") {
      valJson = (val as CrudModel).toPlainObject(true);
    }

    Object.keys(valJson).forEach((propName) => {
      const property = this.findProperty(propName);
      if (!property) {
        return console.warn(
          `Extra prop in ${this.typeLabel} constructor data: ${propName}`,
          val
        );
      }

      property.set(valJson[propName], skipMarkingAsUnsaved);
    });
  }

  protected setDefaults(alreadySetData: object | CrudModel = {}) {
    // if CrudModel was passed, serialize it
    if (typeof (alreadySetData as CrudModel).toPlainObject === "function")
      alreadySetData = (alreadySetData as CrudModel).serializedPayload;

    this._properties.forEach((property) => {
      if (property.serializedName in alreadySetData) return;
      if (!property.hasDefault) return;

      property.maybeSetDefault();
    });
  }

  public setProperty(propertyQuery: CrudPropertyQuery, val: unknown) {
    const property = this.findProperty(propertyQuery);
    if (!property) return;

    property.set(val);
  }

  public setField(fieldArg: CrudFieldQuery, val: unknown) {
    const field = this.findField(fieldArg);
    if (!field) return;

    field.set(val);
  }

  public clone(): CrudModel {
    return new this._classRef(this.typedPayload);
  }

  public reset(): CrudModel {
    this.markAsSaved();

    this.set(this._data, true);

    if (this.isNew) this.setDefaults(this._data);

    return this;
  }

  public get isEmpty() {
    return this.properties.every((property) => property.isEmpty);
  }

  public get isTouched() {
    return this.properties.some((property) => property.isTouched);
  }

  public get hasEverBeenTouched() {
    return !this.isNew || this.isTouched;
  }

  public toPlainObject(opts?: IToPlainObjectOpts | boolean) {
    let retVal = {};
    let toPlainObjectOpts = {
      formatted: !opts,
      decorated: !opts,
      onlyProperties: [],
    };

    if (!opts) opts = false;
    if (typeof opts === "boolean")
      toPlainObjectOpts = {
        formatted: !opts,
        decorated: !opts,
        onlyProperties: [],
      };

    this.findProperties(toPlainObjectOpts.onlyProperties).forEach(
      (property) => {
        if (!property.isHydrated) return;

        retVal[property.name] = property.toPlainObject(
          {
            decorated: toPlainObjectOpts.decorated,
            formatted: toPlainObjectOpts.formatted,
          },
          this
        );
      }
    );

    return retVal;
  }

  public get serializedPayload() {
    return (
      this.findProperties()
        .map((property) => {
          return property.serializedPayload;
        })
        .reduce((acc, propertyJson) => {
          return { ...acc, ...propertyJson };
        }) || {}
    );
  }

  public get typedPayload() {
    return this.typedPayloadGuarded() as {};
  }

  public typedPayloadGuarded(visited = new WeakSet<any>()): object | null {
    if (visited.has(this)) return null;
    visited.add(this);

    return (
      this.findProperties()
        .map((property) => {
          return property.typedPayloadGuarded(visited);
        })
        .reduce((acc, propertyJson) => {
          return { ...acc, ...propertyJson };
        }) || {}
    );
  }

  public get serializedChanges() {
    return this.serializedChangesGuarded();
  }

  public serializedChangesGuarded(visited = new WeakSet<any>()) {
    if (visited.has(this)) return;
    visited.add(this);

    const jsonProps = this.findProperties()
      .map((property) => {
        let propertyJson;
        if (this.isNew) {
          // send all non-null properties
          if (property.isEmpty) return;
          propertyJson = property.serializedPayload;
        } else {
          propertyJson = property.serializedChangesPayloadGuarded(visited);
        }

        if (typeof propertyJson === "undefined") return;

        // convert null to ""
        const cleanedPropertyJson = Object.keys(propertyJson)
          .map((prop) => {
            // console.log(prop);
            // @ts-ignore
            const val = propertyJson[prop] === null ? "" : propertyJson[prop];
            return { [prop]: val };
          })
          .reduce((acc, propPiece) => {
            return { ...acc, ...propPiece };
          });

        return cleanedPropertyJson;
      })
      .filter((a) => a) // remove empty vals
      .reduce((acc, propertyJson) => {
        return { ...acc, ...propertyJson };
      }, {});

    if (!jsonProps || Object.keys(jsonProps).length === 0) return;

    return { ...(this.isNew ? {} : { id: this.id }), ...jsonProps };
  }

  public toApiPayload(fullModel = false) {
    const apiJson = this.serializedChanges;
    if (!apiJson) return {};

    return this.objectToFormData(apiJson);
  }

  private objectToFormData(
    obj: Record<string, any>,
    form?: FormData,
    namespace?: string
  ) {
    let fd = form || new FormData();
    let formKey;

    for (let property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (namespace) {
          formKey = `${namespace}[${property}]`;
        } else {
          formKey = property;
        }

        // if the property is an object, but not a File, use recursion.
        if (
          typeof obj[property] === "object" &&
          !(obj[property] instanceof File)
        ) {
          this.objectToFormData(obj[property], fd, formKey);
        } else {
          // if it's a string or a File object
          fd.append(formKey, obj[property]);
        }
      }
    }
    return fd;
  }

  public async awaitProperty(
    propertyQuery: CrudPropertyQuery,
    waitAttempts: number = 0
  ): Promise<CrudProperty> {
    return new Promise((resolve, reject) => {
      // have all properties been instantiated?
      if (this._properties.length == this._classRef.propertyDefinitions.length)
        return resolve(this.getProperty(propertyQuery));

      // keep waiting
      if (waitAttempts < 10)
        return setTimeout(
          () =>
            this.awaitProperty(propertyQuery, ++waitAttempts)
              .then(resolve)
              .catch(reject),
          25
        );

      return reject("Waited too long for property to be instantiated");
    });
  }

  public getProperty(propertyQuery: CrudPropertyQuery): CrudProperty {
    const property = this.findProperty(propertyQuery);

    if (!property) {
      throw new Error(
        // console.error(
        `Unable to findProperty().
        Property "${propertyQuery}" does not exist on model "${this.typeLabel}"`
      );
    }

    return property;
  }

  public findProperty(
    propertyQuery: CrudPropertyQuery
  ): CrudProperty | undefined {
    const properties = this.properties
      .map((property) => property.findProperty(propertyQuery))
      .flat()
      .filter((property) => property);

    if (properties.length > 1)
      console.error(
        `Model.findProperty() returned more than 1 result.
        Check for duplicated property names or use a more specific query.`,
        propertyQuery,
        properties
      );

    return properties[0];
  }

  public static findProperty(propertyQuery: CrudPropertyQuery) {
    return this.refInstance.findProperty(propertyQuery);
  }

  public findField(
    fieldQuery: CrudFieldQuery,
    optionalResult?: boolean
  ): CrudField | undefined {
    const fields = this.fields
      .map((field) => field.findFields(fieldQuery))
      .flat()
      .filter((field) => field);

    if (fields.length > 1) {
      console.error(
        "Model.findField() returned more than 1 result. Use a more specific query.",
        fieldQuery,
        fields
      );
    }

    if (fields.length === 0 && !optionalResult) {
      console.error(
        `Unable to findField(). Field "${fieldQuery}" does not exist on model "${this.typeLabel}"`
      );
    }

    return fields[0];
  }

  public getField(fieldQuery: CrudFieldQuery): CrudField {
    const field = this.findField(fieldQuery);

    if (!field) {
      throw new Error(
        `Unable to getField(). Field "${fieldQuery}" does not exist on model "${this.typeLabel}"`
      );
    }

    return field;
  }

  public async awaitField(
    fieldQuery: CrudFieldQuery,
    waitAttempts: number = 0
  ): Promise<CrudField> {
    return new Promise((resolve, reject) => {
      // have all fields been instantiated?
      if (this._fields.length == this._classRef.fieldDefinitions.length)
        return resolve(this.getField(fieldQuery));

      // keep waiting
      if (waitAttempts < 10)
        return setTimeout(
          () =>
            this.awaitField(fieldQuery, ++waitAttempts)
              .then(resolve)
              .catch(reject),
          25
        );

      return reject("Waited too long for field to be instantiated");
    });
  }

  public findProperties(
    propertyQueries?: CrudPropertyQuery[] | CrudPropertyQuery,
    ownFieldsOnly = false
  ): CrudProperty[] {
    if (!propertyQueries) propertyQueries = [];
    if (!Array.isArray(propertyQueries)) propertyQueries = [propertyQueries];
    if (propertyQueries.length == 0 || !propertyQueries[0])
      return ownFieldsOnly
        ? this.properties
        : (this.properties
            .map((property) => property.findProperties(propertyQueries))
            .flat()
            .filter((f) => f) as CrudProperty[]);

    const properties = propertyQueries
      .map((reqProperty) => this.findProperty(reqProperty))
      .flat()
      .filter((property) => property) as CrudProperty[];
    return properties;
  }

  public static findProperties(
    propertyQueries?: CrudPropertyQuery[] | CrudPropertyQuery,
    ownFieldsOnly = false
  ) {
    return this.refInstance.findProperties(propertyQueries, ownFieldsOnly);
  }

  public findFields(
    fieldQueries?: CrudFieldQuery[] | CrudFieldQuery,
    ownFieldsOnly = false
  ): CrudField[] {
    if (!fieldQueries) fieldQueries = [];
    if (!Array.isArray(fieldQueries)) fieldQueries = [fieldQueries];
    if (fieldQueries.length == 0 || !fieldQueries[0])
      return ownFieldsOnly
        ? this.fields
        : (this.fields
            .map((field) => field.findFields())
            .flat()
            .filter((f) => f) as CrudField[]);

    const fields = fieldQueries
      .map((fieldQuery) => this.findField(fieldQuery))
      .flat()
      .filter((field) => field) as CrudField[];

    return fields;
  }

  public getFields(fieldQuery: CrudFieldQuery[] | CrudFieldQuery): CrudField[] {
    if (Array.isArray(fieldQuery)) {
      // we map it to getField() so errors are thrown if field is not found
      // (as opposed to using findFields())
      return fieldQuery.length > 0
        ? fieldQuery.map((fieldQuery) => this.getField(fieldQuery))
        : this.findFields();
    }

    return [this.getField(fieldQuery)];
  }

  public static getFields(fieldQuery: CrudFieldQuery[] | CrudFieldQuery) {
    return this.refInstance.getFields(fieldQuery);
  }

  public findFieldsByRelativeId(
    fieldQueries?: CrudFieldQuery[] | CrudFieldQuery,
    ownFieldsOnly = false
  ): CrudFieldsByRelativeId {
    if (
      !fieldQueries ||
      (Array.isArray(fieldQueries) && fieldQueries.length === 0)
    )
      return this.findFields(undefined, ownFieldsOnly)
        .map((field) => ({ [field.id]: field }))
        .reduce((acc, field) => ({ ...acc, ...field }), {});

    if (!Array.isArray(fieldQueries)) fieldQueries = [fieldQueries];

    return fieldQueries
      .map((fieldQuery) => {
        if (typeof fieldQuery === "string")
          return { [fieldQuery]: this.findField(fieldQuery) };

        const field = fieldQuery as CrudField;
        return { [field.id]: field };
      })
      .reduce((acc, field) => {
        return { ...acc, ...field };
      }, {}) as CrudFieldsByRelativeId;
  }

  public static findFieldsByRelativeId(
    fieldQueries?: CrudFieldQuery[] | CrudFieldQuery,
    ownFieldsOnly = false
  ) {
    return this.refInstance.findFieldsByRelativeId(fieldQueries, ownFieldsOnly);
  }

  public getVisibleFields(fieldQueries?: CrudFieldQuery[]): CrudField[] {
    return this.filterVisibleFields(
      this.findFields(fieldQueries).filter((field) => field)
    );
  }

  public filterVisibleFields(fields: CrudField[]): CrudField[] {
    return fields.filter((field) => {
      if (!field) return false;
      return (
        field.isVisibleToUser() &&
        // hide fields that are remotely computed if this is a new model
        (!this.isNew || !field.property.isRemotelyComputed)
      );
    });
  }

  public getEditableFields(fieldQueries?: CrudFieldQuery[]): CrudField[] {
    return this.filterEditableFields(this.findFields(fieldQueries));
  }

  public filterEditableFields(fields: CrudField[]): CrudField[] {
    return fields.filter((field) => {
      if (!field || field.isReadonlyToUser()) return false;
      return field.isVisibleToUser();
    });
  }

  public getPrimaryLabelFields() {
    let fields = this.findFields().filter((field) => field.isPrimaryLabelField);
    fields.sort((a, b) =>
      a.isPrimaryLabelField > b.isPrimaryLabelField ? 1 : -1
    );
    // get first visible field if no primary label field is set
    if (!fields) {
      const firstVisibleField = this.findFields().find((field) =>
        field.isVisibleToUser()
      );
      if (!firstVisibleField) {
        console.error("No label fields available for model " + this.typeLabel);
      }
    }
    return fields;
  }

  public static getPrimaryLabelFields() {
    return this.refInstance.getPrimaryLabelFields();
  }

  public getPrimaryLabelField() {
    return this.findFields().find((field) => field.isPrimaryLabelField);
  }

  public static getPrimaryLabelField() {
    return this.refInstance.getPrimaryLabelField();
  }

  protected _tempId: string | undefined;
  private get tempId() {
    if (!this._tempId) {
      var S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      };
      this._tempId = "_" + (S4() + S4() + "-" + S4());
    }
    return this._tempId;
  }

  public get id() {
    const idProp = this.findProperty("id");
    return !idProp || idProp.isEmpty ? this.tempId : idProp.value;
  }

  protected labelGlue: string = " - ";
  public get label() {
    return this.getPrimaryLabelFields()
      .map((field) => (!field.isEmpty ? field.get() : ""))
      .filter((v) => v)
      .join(this.labelGlue);
  }

  public setLabel(val: string) {
    const primaryLabelFields = this.getPrimaryLabelFields();
    if (!primaryLabelFields) return;

    return primaryLabelFields[0].set(val);
  }

  public static findField(fieldQuery: CrudFieldQuery): CrudField | undefined {
    return this.refInstance.findField(fieldQuery);
  }

  public prop(
    propQuery: CrudPropertyQuery,
    opts?: CrudPropertyGet
  ): any | undefined {
    return this.findProperty(propQuery)?.get(opts);
  }

  public static findFields(
    fieldQueries?: CrudFieldQuery[],
    ownFieldsOnly = false
  ): CrudField[] {
    return this.refInstance.findFields(fieldQueries, ownFieldsOnly);
  }

  public static getVisibleFields(fieldQueries?: CrudFieldQuery[]): CrudField[] {
    return this.refInstance.getVisibleFields();
  }

  public static getEditableFields(
    fieldQueries?: CrudFieldQuery[]
  ): CrudField[] {
    return this.refInstance.getEditableFields();
  }

  public getRelativeFieldLabel(fieldQuery: CrudFieldQuery, prefix = "") {
    const labels = this.fields
      .map((field) => field.getRelativeLabel(fieldQuery, prefix))
      .flat()
      .filter((f) => f);

    if (labels.length > 1) {
      console.error(
        "Model.getRelativeFieldLabel() returned more than 1 result. Use a more specific query.",
        fieldQuery,
        labels
      );
    }

    return labels[0];
  }

  public static getRelativeFieldLabel(fieldQuery: CrudFieldQuery, prefix = "") {
    return this.refInstance.getRelativeFieldLabel(fieldQuery, prefix);
  }

  public getRouteList() {
    return this._classRef.getRouteList();
  }

  public static getRouteList() {
    if (!this.routeBase) return false;

    return "/" + this.routeBase;
  }

  public getRouteNew() {
    return this._classRef.getRouteNew();
  }

  public static getRouteNew() {
    if (!this.routeBase) return false;

    return "/" + this.routeBase + "/new";
  }

  public getRouteSingle(id?: number) {
    if (!id) {
      id = this.id;
      if (this.isNew) return "";
    }

    return this._classRef.getRouteSingle(id ? id : 0);
  }

  public static getRouteSingle(id?: number) {
    if (!this.routeBase) return false;

    return `/${this.routeBase}/${id ? id : "new"}`;
  }

  public static getAsProperty(puralized = false) {
    if (!this.asProperty)
      throw new Error("Default asProperty not set on Model: " + this.typeLabel);
    return this.asProperty + (puralized ? "s" : "");
  }

  public getAsProperty(pluralized?: boolean) {
    return this._classRef.getAsProperty(pluralized);
  }

  public get isNew() {
    return this.getProperty("id").isEmpty;
  }

  public get isValid() {
    return this.properties.every((prop) => prop.isValid);
  }

  public get invalidProperties() {
    return this.properties.filter((prop) => !prop.isValid);
  }

  public get hasUnsavedChanges() {
    return this.hasUnsavedChangesGuarded();
  }

  public hasUnsavedChangesGuarded(visited = new WeakSet<any>()): boolean {
    if (visited.has(this)) return false;
    visited.add(this);

    return this.properties.some((prop) =>
      prop.hasUnsavedChangesGuarded(visited)
    );
  }

  public get unsavedProperties() {
    return this.properties.filter((prop) => prop.hasUnsavedChanges);
  }

  public lastSaved: number = 0;
  private _markingAsSaved = false;
  public markAsSaved() {
    if (this._markingAsSaved) return; // avoid recursion

    this.lastSaved = Date.now();

    this._markingAsSaved = true;
    this.unsavedProperties.forEach((prop) => prop.markAsSaved());
    this._markingAsSaved = false;
  }

  public get wasJustSaved() {
    return this.lastSaved && Date.now() - this.lastSaved < 1000;
  }

  public get isHydrated() {
    return this.properties.every((prop) => prop.isHydrated);
  }

  public get hydratedProperties() {
    return this.properties.filter((prop) => prop.isHydrated);
  }

  public isPartiallyHydratedGuarded(visited = new WeakSet<any>()): boolean {
    if (visited.has(this)) return false;
    visited.add(this);

    return this.properties.some((prop) => prop.isHydratedGuarded(visited));
  }

  public get isLoading() {
    return this._isHydrating || this._isSaving || this._isDeleting;
  }

  protected _isHydrating = false;
  public async hydrate(opts: HydrateModelOpts = {}) {
    if (this.isNew) return this;

    this._isHydrating = true;
    return this.$nuxt.$api
      .getOne(this._classRef, this.id, opts)
      .then((res) => {
        this.set(res.data ? res.data : res, true);
        this.markAsSaved();
        return this;
      })
      .finally(() => {
        this._isHydrating = false;
        return this;
      });
  }

  public static get(query = {}) {
    return this.$nuxt.$api.get(this, query).then((res) => {
      return {
        ...res,
        dataHydrated: res.data.map((data) => new this(data)),
      };
    });
  }

  public static getAll() {
    return this.$nuxt.$api.getAll(this);
  }

  public static getOne(id: number, opts = {}) {
    return this.$nuxt.$api.getOne(this, id, opts).then((res) => {
      if (res.data) return new this(res.data);

      return res;
    });
  }

  protected _isSaving = false;
  public get isSaving() {
    return this._isSaving;
  }

  public save(opts = {}) {
    this._isSaving = true;

    return this.$nuxt.$api
      .update(this, opts)
      .then((result) => {
        if (this.isNew) this.set(result.data, true);
        this.markAsSaved();
      })
      .finally(() => {
        this._isSaving = false;
        return this;
      });
  }

  protected _isDeleted = false;
  public get isDeleted() {
    return this._isDeleted;
  }

  protected _isDeleting = false;
  public get isDeleting() {
    return this._isDeleting;
  }

  public delete() {
    this._isDeleting = true;
    return this.$nuxt.$api
      .delete(this)
      .then(() => (this._isDeleted = true))
      .finally(() => (this._isDeleting = false));
  }

  public static export(query = {}) {
    if (this.$nuxt.$config.useQueuedExports)
      return this.$nuxt.$api.GET(this.api.path + "/export", query);

    return this.$nuxt.$api.export(this, query);
  }
}
